import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { FormControl, Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatSort, MatTableDataSource, MatPaginator, MatFormFieldModule, MatInputModule } from '@angular/material';
import { SuccessdialogComponent } from '../../../handledialog/successdialog/successdialog.component';
import { ModalinfocomplaintComponent } from '../modalinfocomplaint/modalinfocomplaint.component';
import { ModalmainlistComponent } from '../modalmainlist/modalmainlist.component';
import { fuseAnimations } from '../../../../@fuse/animations';
import { ModelCreate } from './modelmenucreate.model';
import { FuseConfirmDialogComponent } from '../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import { HttpClient, HttpEventType } from '@angular/common/http';

export interface Food {
  value: number;
  viewValue: string;
}

export interface Title {
  value: number;
  titlename: string;
}

export interface Projects {
  id: number;
  namepro: string;
}

export interface Typecomplaint {
  name: string;
}
export interface Typecomplaintmaint {
  value: number;
  name: string;
}

@Component({
  selector: 'app-modalmenucreate',
  templateUrl: './modalmenucreate.component.html',
  styleUrls: ['./modalmenucreate.component.scss'],
  animations: fuseAnimations
})

/** modalmenucreate component*/
export class ModalmenucreateComponent implements OnInit {

  public Model: ModelCreate;
  public Depart;
  public Project;
  public droppro: Projects[] = [];
  public send;
  public checkDisplay: boolean = true;
  public projectTopicName;
  productForm: FormGroup;
  projectGalleryForm: FormArray;
  checkValue = false;
  showbuiltype = false;
  showroadtype = false;
  selectedValue: number;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  private baseUrl: string;
  name: string = '';
  email: string = '';
  tel: string = '';
  titlename: string = '';
  messageHTML: string = '';

  foods: Food[] = [
    { value: 1, viewValue: 'รอการชี้แจง' },
    { value: 2, viewValue: 'กำลังดำเนินการแก้ไข' },
    { value: 3, viewValue: 'แจ้งผล' },
    { value: 4, viewValue: 'ปิดเรื่อง' }
  ];
  title: Title[] = [
    { value: 1, titlename: 'นาย' },
    { value: 2, titlename: 'นาง' },
    { value: 3, titlename: 'นางสาว' },
  ];
  typemain: Typecomplaintmaint[] = [
    { value: 1, name: 'งานถนนกับงานสะพาน' },
    { value: 2, name: 'งานอาคาร' },
  ];
  roadtype: Typecomplaint[] = [
    { name: 'ผิวจราจร' },
    { name: 'ทางเท้า' },
    { name: 'ไฟฟ้าแสงสว่าง' },
    { name: 'งานระบบสาธารณูปโภค' },
    { name: 'ป้ายสัญญาณจราจร สัญลักษณ์จราจร' },
    { name: 'ท่อระบายน้ำ' },
    { name: 'อื่นๆ' },
  ];
  buildingtype: Typecomplaint[] = [
    { name: 'ป้ายประกาศ' },
    { name: 'มาตรการป้องกัน' },
    { name: 'การกองวัสดุ' },
    { name: 'ระบบความปลอดภัย' },
    { name: 'เศษวัสดุก่อสร้าง' },
    { name: 'ก่อสร้างผิดแบบ' },
    { name: 'อื่นๆ' },
  ];

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ModalmenucreateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private _formBuilder: FormBuilder,
    public _matDialog: MatDialog,
    private _httpClient: HttpClient
  ) {
    this.baseUrl = "/";
    this.name = '';
    this.email = '';
    this.tel = '';
    this.titlename = '';
    this.messageHTML = '';
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(SuccessdialogComponent, {
      data: {}
    });


  }
  ngOnInit(): void {
    this.getCreateModel();
    this.getDepart();
    this.getOriginalPlan();
    this.productForm = this._formBuilder.group({
      complaintId: [''],
      nameComplaint: ['', [Validators.required]],
      originalPlanId: [''],
      nameProject: [''],
      departmentId: [''],
      c_DepartmentName: [''],
      projectManagerName: [''],
      gpS_Lat: [''],
      gpS_Long: [''],
      distanceLength: [''],
      address: [''],
      telephone: [''],
      email: [''],
      problem: [''],
      problemAddress: [''],
      complaintSubtype: [''],
      complainttype: [''],
      designer: [''],
      supervisor: [''],
      sendDepartmentDate: [''],
      complaintDate: [''],
      complaintStatusId: [''],
      name: {
         titleTH: '',
         firstNameTH: '',
         lastNameTH: '',
      },
      titleTH: [''],
      firstNameTH: [''],
      lastNameTH: [''],
      fileGalleries: [null],
      complaintGalleries: [''],
    });
  }

  //ngOnDestroy() {
  //  unsubscribe();
  //}

  checkAno($event) {
    this.checkValue = $event.checked;

    if (this.checkValue) {
      this.checkDisplay = false;
      this.productForm.get("titleTH").clearValidators();
      this.productForm.get("titleTH").disable();
      this.productForm.get("titleTH").updateValueAndValidity();

      this.productForm.get("firstNameTH").clearValidators();
      this.productForm.get("firstNameTH").disable();
      this.productForm.get("firstNameTH").updateValueAndValidity();

      this.productForm.get("lastNameTH").clearValidators();
      this.productForm.get("lastNameTH").disable();
      this.productForm.get("lastNameTH").updateValueAndValidity();

      this.productForm.get("email").clearValidators();
      this.productForm.get("email").disable();
      this.productForm.get("email").updateValueAndValidity();

      this.productForm.get("telephone").clearValidators();
      this.productForm.get("telephone").disable();
      this.productForm.get("telephone").updateValueAndValidity();
    }
    else {
      this.checkDisplay = true;
      this.productForm.get('titleTH').setValidators([Validators.required]);
      this.productForm.get("titleTH").enable();
      this.productForm.get("titleTH").updateValueAndValidity();

      this.productForm.get('firstNameTH').setValidators([Validators.required]);
      this.productForm.get("firstNameTH").enable();
      this.productForm.get("firstNameTH").updateValueAndValidity();

      this.productForm.get('lastNameTH').setValidators([Validators.required]);
      this.productForm.get("lastNameTH").enable();
      this.productForm.get("lastNameTH").updateValueAndValidity();

      this.productForm.get('email').setValidators([Validators.required, Validators.email]);
      this.productForm.get("email").enable();
      this.productForm.get("email").updateValueAndValidity();

      this.productForm.get('telephone').setValidators([Validators.required]);
      this.productForm.get("telephone").enable();
      this.productForm.get("telephone").updateValueAndValidity();
    }

  }

  changeshow(id?) {
    if (id == 1) {
      this.showroadtype = true;
      this.showbuiltype = false;
    }
    else if (id == 2) {
      this.showbuiltype = true;
      this.showroadtype = false;
    }
  }
  Nobook: string;
  SaveChgangeCreate() {
    this.send = this.productForm.getRawValue();
    this.send.name.titleTH = this.send.titleTH;
    this.send.name.firstNameTH = this.send.firstNameTH;
    this.send.name.lastNameTH = this.send.lastNameTH;
    this.Model = this.send;
    if (this.data.complaintId == null) {
      this.Project.forEach((value) => {
        if (value.OriginalPlanId == this.Model.originalPlanId) {
          this.Model.departmentId = value.DepartmentId;
          this.Model.c_DepartmentName = value.DepartmentName;
          return;
        }
      });
    }

    this.apiService.SaveChgangeCreate(this.Model).subscribe((response) => {
      //console.log(response);
      //this.Nobook = response.complaintNumber;
      this.dialogRef.close();
      if (response != null) {
        if (this.Model.email) {
          this.onSubmit(response);
        }

        this.toDepartment(response);
        const success = this.dialog.open(SuccessdialogComponent, {
          panelClass: 'myapp-no-padding-dialog',
          data: {
            message: response
          }
        });
      }
      //dialogRef.afterClosed().subscribe(result => {

      //});
    });
  };
  get complaintGalleries() {
    return <FormArray>this.productForm.get('complaintGalleries');
  }
  public getCreateModel() {

    this.apiService.getCreate().subscribe((data: ModelCreate) => {
      this.Model = data;
      this.Model.originalPlanId = this.data.Id;
      this.Model.gpS_Lat = this.data.Lat;
      this.Model.gpS_Long = this.data.Long;
      this.Model.projectManagerName = this.data.ProjectManagerName;
      this.Model.designer = this.data.Designer;
      this.Model.supervisor = this.data.Supervisor;
      this.Model.nameProject = this.data.Name;
      if (this.data.complaintId != null) {
        this.Model.departmentId = this.data.departmentId;
        this.Model.c_DepartmentName = this.data.c_DepartmentName;
        this.Model.address = this.data.address;
        this.Model.telephone = this.data.telephone;
        this.Model.email = this.data.email;
        this.Model.name.titleTH = this.data.titleTH;
        this.Model.name.firstNameTH = this.data.firstNameTH;
        this.Model.name.lastNameTH = this.data.lastNameTH;
      }
      this.productForm = this.createProductForm();
      this.projectTopicName = this.data.Name;
    });
  }
  createProductForm(): FormGroup {
    var complaintGalleries = this.Model.complaintGalleries.map(gallery => {
      return this.createGallery(gallery);
    });
    return this._formBuilder.group({
      complaintId: [this.Model.complaintId],
      nameComplaint: [this.Model.nameComplaint, [Validators.required]],
      originalPlanId: [this.Model.originalPlanId],
      nameProject: [this.Model.nameProject],
      departmentId: [this.Model.departmentId],
      c_DepartmentName: [this.Model.c_DepartmentName],
      projectManagerName: [this.Model.projectManagerName],
      gpS_Lat: [this.Model.gpS_Lat],
      gpS_Long: [this.Model.gpS_Long],
      distanceLength: [this.Model.distanceLength],
      address: [this.Model.address],
      telephone: [this.Model.telephone, [Validators.required]],
      email: [this.Model.email, [Validators.required, Validators.email]],
      problem: [this.Model.problem, [Validators.required]],
      problemAddress: [this.Model.problemAddress],
      complaintSubtype: [this.Model.complaintSubtype],
      complainttype: [this.Model.complainttype],
      designer: [this.Model.designer],
      supervisor: [this.Model.supervisor],
      sendDepartmentDate: [this.Model.sendDepartmentDate],
      complaintDate: [this.Model.complaintDate],
      complaintStatusId: [this.Model.complaintStatusId],
      name: {
         titleTH: this.Model.name.titleTH ,
         firstNameTH: this.Model.name.firstNameTH,
         lastNameTH: this.Model.name.lastNameTH,
      },
      titleTH: [this.Model.name.titleTH, [Validators.required]],
      firstNameTH: [this.Model.name.firstNameTH, [Validators.required]],
      lastNameTH: [this.Model.name.lastNameTH, [Validators.required]],
      complaintGalleries: this._formBuilder.array(complaintGalleries),
      fileGalleries: [null]
    });
  }

  createGallery(item?): FormGroup {
    item = item || {};
    return this._formBuilder.group({
      complaintGalleryId: [item.complaintGalleryId],
      picture: [item.picture],
      description: [item.description],
      contentEN: [item.contentEN]
    });
  }


  onFileGalleryChanged(files: any) {
    if (files.length === 0) {
      return;
    }

    this.projectGalleryForm = this.productForm.get('complaintGalleries') as FormArray;

    this.apiService.uploadAvatar(files)
      .then((response: any) => {
        // Set image avatar
        response.dbPaths.forEach(path => {
          let url =  path;
          this.projectGalleryForm.push(this.createGallery({
            complaintGalleryId: 0,
            picture: url,
            description: ''
          }));
        })

      });
  }
  removeGallery(index): void {
    this.projectGalleryForm = this.productForm.get('complaintGalleries') as FormArray;
    this.projectGalleryForm.removeAt(index);
    this.projectGalleryForm.markAsDirty();

  }

  public getDepart() {
    this.apiService.getDepartment().subscribe((data: object[]) => {
      this.Depart = data;
      this.Depart = this.Depart.Results;
    });
  }

  public getOriginalPlan() {
    this.apiService.getOriginalPlan().subscribe((data: object[]) => {
      this.Project = data;
      this.Project = this.Project.Results;
    });
  }

  onSubmit(model) {
    this._httpClient.post(`${this.baseUrl}api/view/sendmail`, {
      name: model.name.fullNameTH,
      email: model.email,
      tel: model.telephone,
      titlename: model.nameComplaint,
      message: model.complaintDate,
      complaintnumber: model.complaintNumber
    }).subscribe(response => {

    });
    this.name = '';
    this.email = '';
    this.tel = '';
    this.titlename = '';
    this.messageHTML = '';
  }

  toDepartment(model) {
    this.Depart.forEach((value) => {
      if (value.OriginalPlanId == model.departmentId) {
        this.email = value.Email;
        this.name = value.DepartmentName;
        return;
      }
    });
    if (this.email != '') { 
      this._httpClient.post(`${this.baseUrl}api/view/sendmail/depart`, {
        name: this.name,
        email: this.email,
        tel: model.telephone,
        titlename: model.nameComplaint,
        message: model.complaintDate,
        complaintnumber: model.complaintNumber
      }).subscribe(response => {

          });
    }
    this.name = '';
    this.email = '';
    this.tel = '';
    this.titlename = '';
    this.messageHTML = '';
  }
}
