import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { FormControl, Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatSort, MatTableDataSource, MatPaginator, MatFormFieldModule, MatInputModule } from '@angular/material';
import { CompletedialogComponent } from '../completedialog/completedialog.component';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';

export interface Typecomplaint {
  value: number;
}
export interface Rating {
  RatingCheckId: number;
  RatingPoint: number;
}

@Component({
    selector: 'app-ratingcheck',
    templateUrl: './ratingcheck.component.html',
    styleUrls: ['./ratingcheck.component.scss'],
    animations: fuseAnimations
})
/** ratingcheck component*/
export class RatingcheckComponent {
  /** ratingcheck ctor */
  public shine = true;
  public Model = 0;
  public Send: {
    RatingCheckId: number;
    RatingPoint: number;
  }
  constructor(public dialog: MatDialog,
    public dialogRef: MatDialogRef<CompletedialogComponent>, public apiService: ApiService) { }
  ngOnInit(): void {
  }
  saveRating(value?) {
    if (value == 0) {
      this.Model = 5;
    }
    //this.Send.RatingCheckId = 0;
    this.Send = {
      RatingCheckId: 0,
      RatingPoint: this.Model,
    }
    this.apiService.SaveRating(this.Send).subscribe((response) => {
      this.dialogRef.close();
      if (response != null) {
        const success = this.dialog.open(CompletedialogComponent, {
          panelClass: 'myapp-no-padding-dialog',
          data: {
            id: 5
          }
        });
      }
      //dialogRef.afterClosed().subscribe(result => {

      //});
    });
  }

}
