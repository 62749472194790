import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { ApiService } from '../../services/api.service';
import { Tree } from '@angular/router/src/utils/tree';
import { SuccessdialogComponent } from '../successdialog/successdialog.component';
import { CompletedialogComponent } from '../completedialog/completedialog.component';
import { ModelInfo } from '../../home/index/modalinfocomplaint/modelinfocomplaint.model';
import { fuseAnimations } from '../../../@fuse/animations';
import { FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { ModalinfocomplaintComponent } from '../../home/index/modalinfocomplaint/modalinfocomplaint.component';
import { HttpClient, HttpEventType } from '@angular/common/http';

@Component({
    selector: 'app-senddepartment',
    templateUrl: './senddepartment.component.html',
    styleUrls: ['./senddepartment.component.scss']
})
/** senddepartment component*/
export class SenddepartmentComponent {
    /** senddepartment ctor */
  public Depart;
  public Model: ModelInfo;
  public Project;
  public send;
  productForm: FormGroup;
  projectGalleryForm: FormArray;
  projectGalleryAnswerForm: FormArray;
  currentUserFullname: string;
  private baseUrl: string;
  name: string = '';
  email: string = '';
  tel: string = '';
  titlename: string = '';
  messageHTML: string = '';

  constructor(public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ModalinfocomplaintComponent>,
    public dialog_send: MatDialogRef<SenddepartmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private _formBuilder: FormBuilder,
    public _matDialog: MatDialog,
    private authenticationService: AuthenticationService,
    private _httpClient: HttpClient) {

    this.currentUserFullname = this.authenticationService.currentUserValue ? this.authenticationService.currentUserValue.fullname : "";
  }

  ngOnInit(): void {
    this.getComplaintInfo();
    this.getDepart();
    this.productForm = this.productForm = this._formBuilder.group({
      complaintId: [''],
      nameComplaint: ['', [Validators.required]],
      originalPlanId: [''],
      nameProject: [''],
      complaintNumber: [''],
      departmentId: [''],
      c_DepartmentName: [''],
      projectManagerName: [''],
      gpS_Lat: [''],
      gpS_Long: [''],
      distanceLength: [''],
      address: [''],
      telephone: [''],
      email: [''],
      problem: [''],
      problemAddress: [''],
      complaintSubtype: [''],
      complainttype: [''],
      designer: [''],
      supervisor: [''],
      sendDepartmentDate: [''],
      complaintDate: [''],
      complaintStatusId: [''],
      name: {
        titleTH: '',
        firstNameTH: '',
        lastNameTH: '',
      },
      titleTH: [''],
      firstNameTH: [''],
      lastNameTH: [''],
      fileGalleries: [null],
      fileGallerieAnswers: [null],
      complaintGalleries: [''],
      complaintAnswerGalleries: [''],
      answer: ['', [Validators.required]],
      answerDate: [''],
      approveDate: [''],
      comfirmDate: [''],
      infomantName: [''],
    });
  }

  public getDepart() {
    this.apiService.getDepartment().subscribe((data: object[]) => {
      this.Depart = data;
      this.Depart = this.Depart.Results;
    });
  }
  public getComplaintInfo() {
    this.apiService.getComplaintInfo(this.data.Id).subscribe((data: ModelInfo) => {
      this.Model = data;
      console.log(this.Model);
      this.productForm = this.createProductForm();
      //this.cancleedit();
    });
  }
  SaveChgangeedit() {
    this.send = this.productForm.getRawValue();
    this.Depart.forEach((value) => {
      if (value.DepartmentId == this.send.departmentId) {
        this.send.c_DepartmentName = value.Name;
        return;
      } 
    });
    this.send.sendDepartmentDate = new Date().toLocaleDateString();
    console.log(this.send.sendDepartmentDate)
    
    this.apiService.SaveChgangeEdit(this.send).subscribe((response) => {
      //console.log(response);
      if (response != null) {
        this.toDepartment(response);
        this.getComplaintInfo();
        response = null;
        const success = this.dialog.open(CompletedialogComponent, {
          panelClass: 'myapp-no-padding-dialog',
          data: {
            //message: response
          }
        });
        success.afterClosed().subscribe(result => {
          this.dialog_send.close();
        });
      }
    });
  };

  createProductForm(): FormGroup {
    var complaintGalleries = this.Model.complaintGalleries.map(gallery => {
      return this.createGallery(gallery);
    });
    var complaintAnswerGalleries = this.Model.complaintAnswerGalleries.map(galleryanswer => {
      return this.createAnswerGallery(galleryanswer);
    });
    return this._formBuilder.group({
      complaintId: [this.Model.complaintId],
      nameComplaint: [this.Model.nameComplaint, [Validators.required]],
      complaintNumber: [this.Model.complaintNumber],
      originalPlanId: [this.Model.originalPlanId],
      nameProject: [this.Model.nameProject],
      departmentId: [this.Model.departmentId],
      c_DepartmentName: [this.Model.c_DepartmentName],
      projectManagerName: [this.Model.projectManagerName],
      gpS_Lat: [this.Model.gpS_Lat],
      gpS_Long: [this.Model.gpS_Long],
      distanceLength: [this.Model.distanceLength],
      address: [this.Model.address],
      telephone: [this.Model.telephone, [Validators.required]],
      email: [this.Model.email, [Validators.required]],
      problem: [this.Model.problem],
      problemAddress: [this.Model.problemAddress],
      complaintSubtype: [this.Model.complaintSubtype],
      complainttype: [this.Model.complainttype],
      designer: [this.Model.designer],
      supervisor: [this.Model.supervisor],
      sendDepartmentDate: [this.Model.sendDepartmentDate],
      complaintDate: [this.Model.complaintDate],
      complaintStatusId: [this.Model.complaintStatusId],
      name: {
        titleTH: this.Model.name.titleTH,
        firstNameTH: this.Model.name.firstNameTH,
        lastNameTH: this.Model.name.lastNameTH,
      },
      titleTH: [this.Model.name.titleTH, [Validators.required]],
      firstNameTH: [this.Model.name.firstNameTH, [Validators.required]],
      lastNameTH: [this.Model.name.lastNameTH, [Validators.required]],
      complaintGalleries: this._formBuilder.array(complaintGalleries),
      fileGalleries: [null],
      fileGallerieAnswers: [null],
      complaintAnswerGalleries: this._formBuilder.array(complaintAnswerGalleries),
      answer: [this.Model.answer, [Validators.required]],
      answerDate: [this.Model.answerDate],
      approveDate: [this.Model.approveDate],
      comfirmDate: [this.Model.comfirmDate],
      infomantName: [this.Model.infomantName],
    });

  }
  createGallery(item?): FormGroup {
    item = item || {};
    return this._formBuilder.group({
      complaintGalleryId: [item.complaintGalleryId],
      picture: [item.picture],
      description: [item.description],
      contentEN: [item.contentEN]
    });
  }


  createAnswerGallery(item?): FormGroup {
    item = item || {};
    return this._formBuilder.group({
      complaintAnswerGalleryId: [item.complaintAnswerGalleryId],
      picture: [item.picture],
      description: [item.description],
      contentEN: [item.contentEN]
    });
  }

  toDepartment(model) {
    this.Depart.forEach((value) => {
      if (value.OriginalPlanId == model.departmentId) {
        this.email = value.Email;
        this.name = value.DepartmentName;
        return;
      }
    });
    if (this.email != '') {
      this._httpClient.post(`${this.baseUrl}api/view/sendmail/depart`, {
        name: this.name,
        email: this.email,
        tel: model.telephone,
        titlename: model.nameComplaint,
        message: model.complaintDate,
        complaintnumber: model.complaintNumber
      }).subscribe(response => {

      });
    }
    this.name = '';
    this.email = '';
    this.tel = '';
    this.titlename = '';
    this.messageHTML = '';
  }
}
