import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-successdialog',
    templateUrl: './successdialog.component.html',
    styleUrls: ['./successdialog.component.scss']
})
/** successdialog component*/
export class SuccessdialogComponent {
  /** successdialog ctor */
  constructor(public dialogRef: MatDialogRef<SuccessdialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    }
}
