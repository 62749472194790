import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : 'Applications',
        translate: 'NAV.APPLICATIONS',
        type     : 'collapsable',
        icon     : 'apps',
        children : [
            {
                id   : 'analytics',
                title: 'Analytics',
                type : 'item',
                url  : '/apps/dashboards/analytics'
            },
            {
                id   : 'project',
                title: 'Project',
                type : 'item',
                url  : '/apps/dashboards/project'
            }
        ]
    }
];
