import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-comfirmdialog',
    templateUrl: './comfirmdialog.component.html',
    styleUrls: ['./comfirmdialog.component.scss']
})
/** successdialog component*/
export class ComfirmdialogComponent {
  /** successdialog ctor */
  constructor(public dialogRef: MatDialogRef<ComfirmdialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    }
}
