import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { ApiService } from 'app/services/api.service';
import { ErrordialogComponent } from '../../handledialog/errordialog/errordialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  returnUrl: string;
  error = '';
  public Send: {
    username: number;
    password: number;
  }
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public apiService: ApiService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  ngOnInit() {
    this.loginForm = this._formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', Validators.required]
    });
  }

  login() {

    if (this.loginForm.invalid) {
      return;
    }
    this.Send = {
      username: this.loginForm.controls.username.value,
      password: this.loginForm.controls.password.value,
    }
    this.apiService.login(this.Send).subscribe((response: any) => {
      if (response != null) {
        //var tss = response.department;
        console.log(response);
        this.authenticationService.login(this.loginForm.controls.username.value, this.loginForm.controls.password.value, response.DepartmentName, response.DepartmentId, response.FullName, response.Roles)
          .subscribe(
            data => {
              this.router.navigate([this.returnUrl]);
            },
            error => {
              this.error = error;
            });
        console.log("login");
      }
      else if (response == null) {
        const error = this.dialog.open(ErrordialogComponent, {
          panelClass: 'myapp-no-padding-dialog',
          data: {
            //message: response
          }
        });
      }
    });
   
  }
  logGuess(): void{
    this.authenticationService.logGuess();
  }
}
