import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-completedialog',
  templateUrl: './completedialog.component.html',
  styleUrls: ['./completedialog.component.scss']
})
/** completedialog component*/
export class CompletedialogComponent {
  /** completedialog ctor */
  constructor(public dialogRef: MatDialogRef<CompletedialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(data);
  }
//  ngOnInit(): void {
//  }
//  if(data == 5 ) {

//}
}
