import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { IndexComponent } from './home/index/index.component';
import { ModalmainlistComponent } from './home/index/modalmainlist/modalmainlist.component';
import { ComplaintendlistComponent } from './home/index/complaintendlist/complaintendlist.component';
import { CheckcomplaintuserComponent } from './home/index/checkcomplaintuser/checkcomplaintuser.component';
import { ModalmenucreateComponent } from './home/index/modalmenucreate/modalmenucreate.component';
import { ModalinfocomplaintComponent } from './home/index/modalinfocomplaint/modalinfocomplaint.component';
import { ErrordialogComponent } from './handledialog/errordialog/errordialog.component';
import { SuccessdialogComponent } from './handledialog/successdialog/successdialog.component';
import { ComfirmdialogComponent } from './handledialog/comfirmdialog/comfirmdialog.component';
import { CompletedialogComponent } from './handledialog/completedialog/completedialog.component';
import { RatingcheckComponent } from './handledialog/ratingcheck/ratingcheck.component';
import { SenddepartmentComponent } from './handledialog/senddepartment/senddepartment.component';
import { CreatesuggestionComponent } from './home/index/createsuggestion/createsuggestion.component';
import { SuggestlistComponent } from './home/index/suggestlist/suggestlist.component';
import { SuggesinfoComponent } from './home/index/suggesinfo/suggesinfo.component';
import { LoginComponent } from './home/login/login.component';
import { ReportcomplaintComponent } from './home/index/reportcomplaint/reportcomplaint.component';
import { ReportfillterComponent } from './handledialog/reportfillter/reportfillter.component';
import { ChartsModule } from 'ng2-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MeterialModule } from '../shared/meterial.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';
import { fuseConfig } from 'app/fuse-config';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { MatButtonModule, MatFormFieldModule, MatIconModule, MatInputModule, MatTableModule, MatTabsModule } from '@angular/material';
// Markdown configurations
import { MarkdownModule } from 'ngx-markdown';

//Esri map
import { ArcgisApiService } from './services/arcgis-api.service';
import { MyService } from './services/my.service';
import { ModalfiltermapComponent } from './home/index/modalfiltermap/modalfiltermap.component';



@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    ModalmainlistComponent,
    CheckcomplaintuserComponent,
    ModalmenucreateComponent,
    ModalfiltermapComponent,
    ModalinfocomplaintComponent,
    ErrordialogComponent,
    SuccessdialogComponent,
    ComfirmdialogComponent,
    ComplaintendlistComponent,
    CompletedialogComponent,
    RatingcheckComponent,
    LoginComponent,
    SenddepartmentComponent,
    CreatesuggestionComponent,
    SuggestlistComponent,
    SuggesinfoComponent,
    ReportcomplaintComponent,
    ReportfillterComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    MeterialModule,
    TranslateModule.forRoot(),


    // Fuse modules
    MatIconModule,
    MatInputModule,
    MatTableModule,
    MatTabsModule,
    FuseWidgetModule,
    ChartsModule,
    NgxChartsModule,
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
    LayoutModule,
    SampleModule,
    MarkdownModule.forRoot(),
    RouterModule.forRoot([
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/main',
        resolve: {
          //data: FrontEndService
        }
      },
      {
        path: 'main',
        component: IndexComponent,
       
      },
      {
        path: 'login',
        component: LoginComponent,
        
      },
      {
        path: '',
        component: ModalmenucreateComponent,
      },
      {
        path: '',
        component: ModalfiltermapComponent,
      },
      {
        path: '',
        component: ModalinfocomplaintComponent,
      },
      {
        path: '',
        component: ErrordialogComponent,
      },
      {
        path: '',
        component: SuccessdialogComponent,
      },
      {
        path: '',
        component: ComfirmdialogComponent,
      },
      {
        path: '',
        component: CompletedialogComponent,
      },
      {
        path: '',
        component: RatingcheckComponent,
      },
      {
        path: '',
        component: SenddepartmentComponent,
      },
      {
        path: '',
        component: CreatesuggestionComponent,
      },
      {
        path: '', 
        component: SuggestlistComponent,
      },
      {
        path: '', 
        component: SuggesinfoComponent,
      },
      {
        path: '',
        component: ReportcomplaintComponent,
      },
      {
        path: '',
        component: ReportfillterComponent,
      },
      {
        path: 'backend',
        loadChildren: './backend/backend.module#BackendModule'
      },
      {
        path: 'frontend',
        loadChildren: './frontend/frontend.module#FrontendModule'
      },
      {
        path: 'archived',
        loadChildren: './archived/archived.module#ArchivedModule'
      },
      {
        path: 'reporting',
        loadChildren: './reporting/reporting.module#ReportingModule'
      },
      {
        path: 'guide',
        loadChildren: './guide/guide.module#GuideModule'
      },
    ])
  ],
  providers: [ArcgisApiService, MyService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
