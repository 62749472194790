import { Component, OnInit, ViewChild, NgModule, OnDestroy } from '@angular/core';
import { ArcgisApiService, MapLayer } from '../../../services';
//import { fuseAnimations } from '@fuse/animations';
import { MatSort, MatTableDataSource, MatPaginator, MatFormFieldModule, MatInputModule } from '@angular/material';
import { Routes, RouterModule } from '@angular/router';
//import { ApiService } from 'app/services/api.service';
import { ApiService } from '../../../services/api.service';
import { fuseAnimations } from '../../../../@fuse/animations';
import { MatDialog } from '@angular/material';
import { ModalinfocomplaintComponent } from '../modalinfocomplaint/modalinfocomplaint.component';
import { AuthenticationService } from 'app/services/authentication.service';


export interface Food {
  value: number;
  viewValue: string;
}

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
  department: string;
  status: number;
  telephone: string;
}
export interface ComplaintElement {
  complaintId: number;
  nameComplaint: string;
  nameProject: string;
  //Name: string;
  c_DepartmentName: string;
  complaintDate: Date;
  statusName: string;
  telephone: string;
}



@Component({
  selector: 'app-modalmainlist',
  templateUrl: './modalmainlist.component.html',
  styleUrls: ['./modalmainlist.component.scss'],
  animations: fuseAnimations
})
/** modalmainlist component*/
export class ModalmainlistComponent implements OnDestroy {
  public listcomplaint: ComplaintElement[];
  displayedColumns: string[] = ['position', 'nameComplaint', 'nameUserComplaint', 'nameProject', 'c_DepartmentName', 'complaintDate', 'statusName', 'telephone','complaintId'];
  dataSource = new MatTableDataSource();
  selectedValue: number;
  fillterName: string;
  fillterNameProject: string;
  fillterNameComplaint: string;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  currentUserDepartmentId: number;
  currentUserRole: any;
  foods: Food[] = [
    { value: 1, viewValue: 'รอการชี้แจง' },
    { value: 2, viewValue: 'กำลังดำเนินการแก้ไข' },
    { value: 3, viewValue: 'แจ้งผล' },
    { value: 4, viewValue: 'ปิดเรื่อง' }
  ];
  constructor(private apiService: ApiService, public dialog: MatDialog, private authenticationService: AuthenticationService) {

  }
  openDetail(Id) {
    const dialogRef = this.dialog.open(ModalinfocomplaintComponent, {
      panelClass: 'myapp-no-padding-dialog',
      data: {
        Id: Id,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getComplaintList();
    });
  }
  ngOnInit() {
    this.currentUserDepartmentId = this.authenticationService.currentUserValue ? this.authenticationService.currentUserValue.departmentId : 0;
    this.currentUserRole = this.authenticationService.currentUserValue ? this.authenticationService.currentUserValue.roles : null;
    var checkSuperadminComplaint = this.currentUserRole.includes("Super Admin(Complaint)");
    var checkSuperadmin = this.currentUserRole.includes("Super Admin");
    if (checkSuperadminComplaint || checkSuperadmin) {
      this.getComplaintList();
    }
    else {
      this.getComplaintList(this.currentUserDepartmentId);
    }
   



  }
  //applyFilter(filterValue: string) {
  //  this.dataSource.filter = filterValue.trim().toLowerCase();
  //}
  public getComplaintList(id?) {
    this.apiService.getComplaintlist(id).subscribe((data: ComplaintElement[]) => {
      this.listcomplaint = data;
      //console.log(this.listcomplaint);
      this.dataSource = new MatTableDataSource(this.listcomplaint);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.filterPredicate =
        (data: ComplaintElement, filtersJson: string) => {
          const matchFilter = [];
          const filters = JSON.parse(filtersJson);

          filters.forEach(filter => {
            if (filter.idInner == null) {
              const val = data[filter.id] === null ? '' : data[filter.id];
              matchFilter.push(val.toLowerCase().includes(filter.value.toLowerCase()));
            }
            else {
              const val = data[filter.id][filter.idInner] === null ? '' : data[filter.id][filter.idInner];
              matchFilter.push(val.toLowerCase().includes(filter.value.toLowerCase()));
            }

          });
          return matchFilter.every(Boolean);
        };

      //console.log(this.listcomplaint);
    });
 
  }
  public doFilter = (value: string, column: string, inner: string) => {

    const tableFilters = [];
    tableFilters.push({
      id: column,
      idInner: inner,
      value: value
    });

    this.dataSource.filter = JSON.stringify(tableFilters);

    //this.dataSource.filter = value.trim().toLocaleLowerCase();
    this.dataSource.paginator.firstPage();
  }

  ngOnDestroy(): void {
    this.apiService.getComplaintlist();
  }
}
