import { Component, OnInit, ViewChild, NgModule } from '@angular/core';
import { ArcgisApiService, MapLayer } from '../../../services';
import { fuseAnimations } from '@fuse/animations';
import { MatSort, MatTableDataSource, MatPaginator, MatFormFieldModule, MatInputModule } from '@angular/material';
import { Routes, RouterModule } from '@angular/router';
import { ApiService } from 'app/services/api.service';
import { MatDialog } from '@angular/material';
import { ModalinfocomplaintComponent } from '../modalinfocomplaint/modalinfocomplaint.component';
import { NoopAnimationPlayer } from '@angular/animations';
import { AuthenticationService } from 'app/services/authentication.service';

export interface Food {
  value: number;
  viewValue: string;
}

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
  department: string;
  status: number;
  telephone: string;
}
export interface ComplaintElement {
  complaintId: number;
  nameComplaint: string;
  nameProject: string;
  //Name: string;
  c_DepartmentName: string;
  complaintDate: Date;
  statusName: string;
  telephone: string;
}

@Component({
    selector: 'app-complaintendlist',
    templateUrl: './complaintendlist.component.html',
    styleUrls: ['./complaintendlist.component.scss'],
    animations: fuseAnimations
})
/** complaintendlist component*/
export class ComplaintendlistComponent {
  public listcomplaintend: ComplaintElement[];
  displayedColumns: string[] = ['position', 'nameComplaint', 'nameUserComplaint', 'nameProject', 'c_DepartmentName', 'complaintDate','answerDate', 'statusName', 'telephone', 'complaintId'];
  dataSource = new MatTableDataSource();
  selectedValue: number;
  fillterName: string;
  fillterNameProject: string;
  fillterNameComplaint: string;
  currentUserDepartmentId: number;
  currentUserRole: any;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  foods: Food[] = [
    { value: 1, viewValue: 'รอการชี้แจง' },
    { value: 2, viewValue: 'กำลังดำเนินการแก้ไข' },
    { value: 3, viewValue: 'แจ้งผล' },
    { value: 4, viewValue: 'ปิดเรื่อง' }
  ];
  constructor(private apiService: ApiService, public dialog: MatDialog, private authenticationService: AuthenticationService) {

  }
  openDetail(Id) {
    const dialogRef = this.dialog.open(ModalinfocomplaintComponent, {
      panelClass: 'myapp-no-padding-dialog',
      // panelClass: 'bookCardDialog',
      // maxWidth: '100vw',
      // maxHeight: '100vh',
      // height: '80%',
      // width: '80%',
      data: {
        Id: Id,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getComplaintlistEnd();
    });
  }
  ngOnInit() {
    var isShow = [];
    
    this.currentUserDepartmentId = this.authenticationService.currentUserValue ? this.authenticationService.currentUserValue.departmentId : 0;
    this.currentUserRole = this.authenticationService.currentUserValue ? this.authenticationService.currentUserValue.roles : null;
    var checkSuperadminComplaint = this.currentUserRole.includes("Super Admin(Complaint)");
    var checkSuperadmin = this.currentUserRole.includes("Super Admin");
    if (checkSuperadminComplaint || checkSuperadmin) {
      this.getComplaintlistEnd();
    }
    else {
      this.getComplaintlistEnd(this.currentUserDepartmentId);
     
    }
    
  }



  //applyFilter(filterValue: string) {
  //  this.dataSource.filter = filterValue.trim().toLowerCase();
  //}
  public getComplaintlistEnd(currentUserDepartmentId?) {
    this.apiService.getComplaintlistEnd(currentUserDepartmentId).subscribe((data: ComplaintElement[]) => {
      this.listcomplaintend = data;
      //console.log(this.listcomplaint);
      this.dataSource = new MatTableDataSource(this.listcomplaintend);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.filterPredicate =
        (data: ComplaintElement, filtersJson: string) => {
          const matchFilter = [];
          const filters = JSON.parse(filtersJson);

          filters.forEach(filter => {
            if (filter.idInner == null) {
              const val = data[filter.id] === null ? '' : data[filter.id];
              matchFilter.push(val.toLowerCase().includes(filter.value.toLowerCase()));
            }
            else {
              const val = data[filter.id][filter.idInner] === null ? '' : data[filter.id][filter.idInner];
              matchFilter.push(val.toLowerCase().includes(filter.value.toLowerCase()));
            }

          });
          return matchFilter.every(Boolean);
        };
      //console.log(this.listcomplaintend);
    });
  }
  public doFilter = (value: string, column: string, inner: string) => {

    const tableFilters = [];
    tableFilters.push({
      id: column,
      idInner: inner,
      value: value
    });

    this.dataSource.filter = JSON.stringify(tableFilters);

    //this.dataSource.filter = value.trim().toLocaleLowerCase();
    this.dataSource.paginator.firstPage();
  }
}
