import { Component, OnInit, ViewChild, NgModule } from '@angular/core';
import { ArcgisApiService, MapLayer } from '../../../services';
import { fuseAnimations } from '@fuse/animations';
import { MatSort, MatTableDataSource, MatPaginator, MatDialog } from '@angular/material';
import { Routes, RouterModule } from '@angular/router';
import { ApiService } from 'app/services/api.service';
import { ModelInfo } from './checkcomplaintuser-model';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RatingcheckComponent } from '../../../handledialog/ratingcheck/ratingcheck.component';
import { ModalmenucreateComponent } from '../../index/modalmenucreate/modalmenucreate.component';
export interface Paramscheck {
  ComplaintNumber: string;
  Password: string;
}
@Component({
    selector: 'app-checkcomplaintuser',
    templateUrl: './checkcomplaintuser.component.html',
    styleUrls: ['./checkcomplaintuser.component.scss'],
    animations: fuseAnimations
})
/** checkcomplaintuser component*/
export class CheckcomplaintuserComponent implements OnInit {
  /** checkcomplaintuser ctor */
  ComplaintNumber: string;
  Password: string;
  productForm: FormGroup;
  projectGalleryForm: FormArray;
  projectGalleryAnswerForm: FormArray;
  public send;
  //show: boolean;
  shows = false;
  public Params = {
    ComplaintNumber: '',
    Password: '',
  };
  //this.Params = new
  public Modelshow: ModelInfo;
  public Modssssel;
  constructor(private apiService: ApiService, private _formBuilder: FormBuilder, public _matdialograting: MatDialog ) { }

  ngOnInit(): void {
    this.productForm = this.productForm = this._formBuilder.group({
      complaintId: [''],
      nameComplaint: ['', [Validators.required]],
      originalPlanId: [''],
      nameProject: [''],
      complaintNumber: [''],
      departmentId: [''],
      c_DepartmentName: [''],
      projectManagerName: [''],
      gpS_Lat: [''],
      gpS_Long: [''],
      distanceLength: [''],
      address: [''],
      telephone: [''],
      email: [''],
      problem: [''],
      problemAddress: [''],
      complaintSubtype: [''],
      complainttype: [''],
      designer: [''],
      supervisor: [''],
      sendDepartmentDate: [''],
      complaintDate: [''],
      complaintStatusId: [''],
      name: {
        titleTH: '',
        firstNameTH: '',
        lastNameTH: '',
      },
      titleTH: [''],
      firstNameTH: [''],
      lastNameTH: [''],
      fileGalleries: [null],
      fileGallerieAnswers: [null],
      complaintGalleries: [''],
      complaintAnswerGalleries: [''],
      answer: [''],
      answerDate: [''],
      approveDate: [''],
      comfirmDate: [''],
      infomantName: [''],
    });
  }

  CheckInfoUser() {
    //this.Params = [{ ComplaintNumber: this.ComplaintNumber, Password: this.Password }]
    //console.log(this.Params);
    this.apiService.getCheckUser(this.Params).subscribe((response: ModelInfo) => {
      this.Modelshow = response;
      console.log(this.Modelshow);
      this.productForm = this.createProductForm();
      console.log(response);
      if (this.Modelshow.complaintNumber != null) {
        this.shows = true;
      }
    });
  };
  SaveChgangeedit(id) {
    this.send = this.productForm.getRawValue();
    if (id > 0 && id != 5) {
      this.send.complaintStatusId = id;
    }
    if (id == 4) {
      this.send.comfirmDate = new Date().toLocaleDateString();
    }
    this.apiService.SaveChgangeEdit(this.send).subscribe((responseanswer) => {
      //console.log(response);
      if (responseanswer != null) {
        this.CheckInfoUser();
        responseanswer = null;
        this.openDialogRating();
      }
    });
  };

  AgainComplaint(id) {
    this.send = this.productForm.getRawValue();
    if (id == 4) {
      this.send.comfirmDate = new Date().toLocaleDateString();
      this.send.complaintStatusId = id;
    }
    this.apiService.SaveChgangeEdit(this.send).subscribe((responseanswer) => {
      //console.log(response);
      if (responseanswer != null) {
        this.createnew(responseanswer);
      }
    });
  };
  createnew(responseanswer): void {
    const ratinfRef = this._matdialograting.open(ModalmenucreateComponent, {
      panelClass: 'myapp-no-padding-dialog',
      data: {
        Id: responseanswer.originalPlanId,
        Long: responseanswer.gpS_Long,
        Lat: responseanswer.gpS_Lat,
        ProjectManagerName: responseanswer.projectManagerName,
        Designer: responseanswer.designer,
        Supervisor: responseanswer.supervisor,
        Name: responseanswer.nameProject,
        departmentId: responseanswer.departmentId,
        c_DepartmentName: responseanswer.c_DepartmentName,
        address: responseanswer.address,
        telephone: responseanswer.telephone,
        email: responseanswer.email,
        titleTH: responseanswer.name.titleTH,
        firstNameTH: responseanswer.name.firstNameTH,
        lastNameTH: responseanswer.name.lastNameTH,
        complaintId: responseanswer.complaintId,
      }
    });

    ratinfRef.afterClosed().subscribe(result => {
      responseanswer = null;
      this.shows = false;
      this.Params = {
        ComplaintNumber: '',
        Password: '',
      };
    });
  }
  createGallery(item?): FormGroup {
    item = item || {};
    return this._formBuilder.group({
      complaintGalleryId: [item.complaintGalleryId],
      picture: [item.picture],
      description: [item.description],
      contentEN: [item.contentEN]
    });
  }


  createAnswerGallery(item?): FormGroup {
    item = item || {};
    return this._formBuilder.group({
      complaintAnswerGalleryId: [item.complaintAnswerGalleryId],
      picture: [item.picture],
      description: [item.description],
      contentEN: [item.contentEN]
    });
  }
  openDialogRating(): void {
    const ratinfRef = this._matdialograting.open(RatingcheckComponent, {
      panelClass: 'myapp-no-padding-dialog',
      data: {}
    });

    ratinfRef.afterClosed().subscribe(result => {
      this.shows = false;
      this.Params = {
        ComplaintNumber: '',
        Password: '',
      };
    });
  }
  get complaintAnswerGalleries() {
    return <FormArray>this.productForm.get('complaintAnswerGalleries');
  }

  get complaintGalleries() {
    return <FormArray>this.productForm.get('complaintGalleries');
  }
  createProductForm(): FormGroup {
    var complaintGalleries = this.Modelshow.complaintGalleries.map(gallery => {
      return this.createGallery(gallery);
    });
    var complaintAnswerGalleries = this.Modelshow.complaintAnswerGalleries.map(galleryanswer => {
      return this.createAnswerGallery(galleryanswer);
    });
    return this._formBuilder.group({
      complaintId: [this.Modelshow.complaintId],
      nameComplaint: [this.Modelshow.nameComplaint, [Validators.required]],
      complaintNumber: [this.Modelshow.complaintNumber],
      originalPlanId: [this.Modelshow.originalPlanId],
      nameProject: [this.Modelshow.nameProject],
      departmentId: [this.Modelshow.departmentId],
      c_DepartmentName: [this.Modelshow.c_DepartmentName],
      projectManagerName: [this.Modelshow.projectManagerName],
      gpS_Lat: [this.Modelshow.gpS_Lat],
      gpS_Long: [this.Modelshow.gpS_Long],
      distanceLength: [this.Modelshow.distanceLength],
      address: [this.Modelshow.address],
      telephone: [this.Modelshow.telephone, [Validators.required]],
      email: [this.Modelshow.email, [Validators.required]],
      problem: [this.Modelshow.problem],
      problemAddress: [this.Modelshow.problemAddress],
      complaintSubtype: [this.Modelshow.complaintSubtype],
      complainttype: [this.Modelshow.complainttype],
      designer: [this.Modelshow.designer],
      supervisor: [this.Modelshow.supervisor],
      sendDepartmentDate: [this.Modelshow.sendDepartmentDate],
      complaintDate: [this.Modelshow.complaintDate],
      complaintStatusId: [this.Modelshow.complaintStatusId],
      name: {
        titleTH: this.Modelshow.name.titleTH,
        firstNameTH: this.Modelshow.name.firstNameTH,
        lastNameTH: this.Modelshow.name.lastNameTH,
      },
      titleTH: [this.Modelshow.name.titleTH, [Validators.required]],
      firstNameTH: [this.Modelshow.name.firstNameTH, [Validators.required]],
      lastNameTH: [this.Modelshow.name.lastNameTH, [Validators.required]],
      complaintGalleries: this._formBuilder.array(complaintGalleries),
      fileGalleries: [null],
      fileGallerieAnswers: [null],
      complaintAnswerGalleries: this._formBuilder.array(complaintAnswerGalleries),
      answer: [this.Modelshow.answer],
      answerDate: [this.Modelshow.answerDate],
      approveDate: [this.Modelshow.approveDate],
      comfirmDate: [this.Modelshow.comfirmDate],
      infomantName: [this.Modelshow.infomantName],
    });

  }
  //public getParams() {
  //  this.apiService.getParams().subscribe((data: object[]) => {
  //    this.Params = data;

  //  });
  //}
  //public getCreateModel() {
  //  this.apiService.getCreate().subscribe((data: object[]) => {
  //    this.Model = data;
      
  //  });
  //}
}
