import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { FormControl, Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatSort, MatTableDataSource, MatPaginator, MatFormFieldModule, MatInputModule } from '@angular/material';
import { SuccessdialogComponent } from '../../../handledialog/successdialog/successdialog.component';
import { CompletedialogComponent } from '../../../handledialog/completedialog/completedialog.component';
import { ModalinfocomplaintComponent } from '../modalinfocomplaint/modalinfocomplaint.component';
import { ModalmainlistComponent } from '../modalmainlist/modalmainlist.component';
import { fuseAnimations } from '../../../../@fuse/animations';
import { ModelSuggestCreate } from '../createsuggestion/createsuggestion.model';
import { FuseConfirmDialogComponent } from '../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import { HttpClient, HttpEventType } from '@angular/common/http';

@Component({
    selector: 'app-suggesinfo',
    templateUrl: './suggesinfo.component.html',
    styleUrls: ['./suggesinfo.component.scss'],
    animations: fuseAnimations
})
/** suggesinfo component*/
export class SuggesinfoComponent implements OnInit {
  public Model: ModelSuggestCreate;
  public Project;
  public send;
  public projectTopicName;
  productForm: FormGroup;
  projectGalleryForm: FormArray;
  projectGalleryAnswerForm: FormArray;

  constructor(public dialog: MatDialog,
    public matDialogRef: MatDialogRef<SuggesinfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private _formBuilder: FormBuilder,
    public _matDialog: MatDialog) {

  }
  ngOnInit(): void {
    this.getSuggestInfo();
    this.getOriginalPlan();
    this.productForm = this._formBuilder.group({
      suggestionId: [''],
      nameSuggestion: ['', [Validators.required]],
      originalPlanId: [''],
      nameProject: [''],
      departmentId: [''],
      c_DepartmentName: [''],
      projectManagerName: [''],
      gpS_Lat: [''],
      gpS_Long: [''],
      distanceLength: [''],
      address: [''],
      telephone: [''],
      email: [''],
      suggestion: [''],
      problemAddress: [''],
      designer: [''],
      supervisor: [''],
      suggestionDate: [''],
      name: {
        titleTH: '',
        firstNameTH: '',
        lastNameTH: '',
        fullNameTH:'',
      },
      titleTH: [''],
      firstNameTH: [''],
      lastNameTH: [''],
      fullTH:[''],
      fileGalleries: [null],
      suggestionGalleries: [''],
    });
  }
  get suggestionGalleries() {
    return <FormArray>this.productForm.get('suggestionGalleries');
  }
  public getSuggestInfo() {
    this.apiService.getSuggestInfo(this.data.Id).subscribe((data: ModelSuggestCreate) => {
      this.Model = data;
      this.productForm = this.createProductForm();
      this.projectTopicName = this.Model.nameProject;
      //this.cancleedit();
    });
  }
  createProductForm(): FormGroup {
    var suggestionGalleries = this.Model.suggestionGalleries.map(gallery => {
      return this.createGallery(gallery);
    });
    return this._formBuilder.group({
      suggestionId: [this.Model.suggestionId],
      nameSuggestion: [this.Model.nameSuggestion, [Validators.required]],
      originalPlanId: [this.Model.originalPlanId],
      nameProject: [this.Model.nameProject],
      departmentId: [this.Model.departmentId],
      c_DepartmentName: [this.Model.c_DepartmentName],
      projectManagerName: [this.Model.projectManagerName],
      gpS_Lat: [this.Model.gpS_Lat],
      gpS_Long: [this.Model.gpS_Long],
      distanceLength: [this.Model.distanceLength],
      address: [this.Model.address],
      telephone: [this.Model.telephone, [Validators.required]],
      email: [this.Model.email, [Validators.required]],
      suggestion: [this.Model.suggestion, [Validators.required]],
      problemAddress: [this.Model.problemAddress],
      designer: [this.Model.designer],
      supervisor: [this.Model.supervisor],
      suggestionDate: [this.Model.suggestionDate],
      name: {
        titleTH: this.Model.name.titleTH,
        firstNameTH: this.Model.name.firstNameTH,
        lastNameTH: this.Model.name.lastNameTH,
        fullNameTH: this.Model.name.fullNameTH,
      },
      titleTH: [this.Model.name.titleTH, [Validators.required]],
      firstNameTH: [this.Model.name.firstNameTH, [Validators.required]],
      lastNameTH: [this.Model.name.lastNameTH, [Validators.required]],
      fullTH: [this.Model.name.fullNameTH],
      suggestionGalleries: this._formBuilder.array(suggestionGalleries),
      fileGalleries: [null]
    });
  }
  createGallery(item?): FormGroup {
    item = item || {};
    return this._formBuilder.group({
      suggestionGalleryId: [item.suggestionGalleryId],
      picture: [item.picture],
      description: [item.description],
      contentEN: [item.contentEN]
    });
  }

  onFileGalleryChanged(files: any) {
    if (files.length === 0) {
      return;
    }

    this.projectGalleryForm = this.productForm.get('suggestionGalleries') as FormArray;

    this.apiService.uploadAvatar(files)
      .then((response: any) => {
        // Set image avatar
        response.dbPaths.forEach(path => {
          let url = path;
          this.projectGalleryForm.push(this.createGallery({
            suggestionGalleryId: 0,
            picture: url,
            description: ''
          }));
        })

      });
  }

  removeGallery(index): void {
    this.projectGalleryForm = this.productForm.get('suggestionGalleries') as FormArray;
    this.projectGalleryForm.removeAt(index);
    this.projectGalleryForm.markAsDirty();

  }

  public getOriginalPlan() {
    this.apiService.getOriginalPlan().subscribe((data: object[]) => {
      this.Project = data;
      this.Project = this.Project.Results;
    });
  }

}
