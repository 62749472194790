import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { map, catchError, finalize } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
@Injectable({
  providedIn: 'root'
})

export class ApiService implements Resolve<any> {
  routeParams: any;
  onUploadAvatar: BehaviorSubject<any>;
  API_URL = 'http://localhost:65000';
  API_PRODUCTION = 'http://bkkyota.com/';
  currentUserDepartmentId: any;
  currentUsername: string;
  private baseUrl: string;

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.routeParams = route.params;
    return new Promise((resolve, reject) => {
      Promise.all([
        //this.getProject()
      ]).then(
        () => {
          resolve();
        },
        reject
      );
    });
  }

  constructor(private httpClient: HttpClient, location: Location)
  {
    this.baseUrl = "/";
    this.onUploadAvatar = new BehaviorSubject({});
  //  this.currentUsername = this.authenticationService.currentUserValue ? this.authenticationService.currentUserValue.username : "Guest";
  //  this.currentUserDepartmentId = this.authenticationService.currentUserValue ? this.authenticationService.currentUserValue.departmentId : null;
  }
  getComplaintlist(currentUserDepartmentId?) {

    let params = new HttpParams();
    if (currentUserDepartmentId) {
      params = params.append('DepartmentId', currentUserDepartmentId);
    }
    return this.httpClient.get(`${location.origin}/api/complaint/complaintlist`, { params: params});
  }
  getComplaintlistEnd(currentUserDepartmentId?) {
    let params = new HttpParams();
    if (currentUserDepartmentId) {
      params = params.append('DepartmentId', currentUserDepartmentId);
    }
   
    return this.httpClient.get(`${location.origin}/api/complaint/complaintlistend`, { params: params });
  }
  getCreate() {
    return this.httpClient.get(`${location.origin}/api/complaint/complaintinfo/` + 0);
  }
  getComplaintInfo(Id) {
    return this.httpClient.get(`${location.origin}/api/complaint/complaintinfo/` + Id);
  }
  getDepartment() {
    return this.httpClient.get(`${location.origin}/proxy/misservice2/api/master/departments/complaint`);
  }
  getOriginalPlan() {
    return this.httpClient.get(`${location.origin}/proxy/misservice2/api/tracksetup/projectReceived/complaint`);
  }
  SaveChgangeCreate(Model) {
    return this.httpClient.post(`${location.origin}/api/complaint/complaintinfo`, Model);
  }
  getCheckUser(Params) {
    return this.httpClient.post(`${location.origin}/api/complaint/complaintinfocheck`, Params);
  }
  getParams() {
    return this.httpClient.get(`${location.origin}/api/complaint/paramscomplaint`);
  }
  SaveChgangeEdit(Model) {
    return this.httpClient.put(`${location.origin}/api/complaint/complaintinfo`, Model);
  }
  SaveRating(Model) {
    return this.httpClient.post(`${location.origin}/api/complaint/rating`, Model);
  }
  login(Model) {
    return this.httpClient.post(`${location.origin}/proxy/misservice2/api/masteruser/complaint`, Model);
  }

  SaveSuggestion(Model) {
    return this.httpClient.post(`${location.origin}/api/suggestion/suggestinfo`, Model);
  }
  getSuggestInfo(Id) {
    return this.httpClient.get(`${location.origin}/api/suggestion/suggestinfo/` + Id);
  }
  getSuggestlist() {
    return this.httpClient.get(`${location.origin}/api/suggestion/suggestlist`);
  }
  getCreateSuggest() {
    return this.httpClient.get(`${location.origin}/api/suggestion/suggestinfo/` + 0);
  }
  getPathPic(id) {
    return this.httpClient.get(`${location.origin}/proxy/misservice2/api/tracksetup/pathpic/` + id);
  }
  getStatus(id) {
    return this.httpClient.get(`${location.origin}/proxy/misservice2/api/publicutility/` + id + `/for-map`);
  }
  getAllStatus(Params) {
    let params = new HttpParams();
    if (Params) {
      params = params.append('year', Params+543);
    }
    return this.httpClient.get(`${location.origin}/proxy/misservice2/api/publicutility/byapprovalstatus`, { params: params });
  }
  saveSearchTime(searchTime) {
    return this.httpClient.post(`${location.origin}/api/report/saveSearchTime`, searchTime);
  }

  uploadAvatar(files: any) {
    const formData = new FormData();

    for (let file of files)
      formData.append("files", file);

    return new Promise((resolve, reject) => {
      this.httpClient.post(`${location.origin}/api/complaint/api/project/uploadfiles`, formData, {
        reportProgress: true,
        observe: 'events'
      })
        .subscribe((response: any) => {
          if (response.type === HttpEventType.UploadProgress)
            this.onUploadAvatar.next({
              progress: Math.round(100 * response.loaded / response.total)
            });
          else if (response.type === HttpEventType.Response)
            resolve(response.body);
        }, reject);
    });
  }
}
@Injectable()
export class HTTPListener implements HttpInterceptor {
  constructor(private status: ApiService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map(event => {
        return event;
      }),
      catchError(error => {
        return Observable.throw(error);
      }),
      //finalize(() => {
      //  this.status.setHttpStatus(false);
      //})
    )
  }
}
