import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-errordialog',
    templateUrl: './errordialog.component.html',
    styleUrls: ['./errordialog.component.scss']
})
/** errordialog component*/
export class ErrordialogComponent {
    /** errordialog ctor */
  constructor(public dialogRef: MatDialogRef<ErrordialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {

    }
}
