import { Component, OnInit, ViewChild, NgModule, OnDestroy, Inject } from '@angular/core';
import { ArcgisApiService, MapLayer } from '../../../services';
//import { fuseAnimations } from '@fuse/animations';
import { MatSort, MatTableDataSource, MatPaginator, MatFormFieldModule, MatInputModule } from '@angular/material';
import { Routes, RouterModule } from '@angular/router';
//import { ApiService } from 'app/services/api.service';
import { ApiService } from '../../../services/api.service';
import { fuseAnimations } from '../../../../@fuse/animations';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReportfillterComponent } from '../../../handledialog/reportfillter/reportfillter.component';
import { HttpClient, HttpEventType, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { saveAs } from 'file-saver';
import * as fileSaver from 'file-saver';
import * as $ from "jquery";
export interface ReportElement {
  name: string;
  position: number;
  type: number;
  description: string;
  ReportFileName: string;
  FXViewer: boolean;
  LoadPDFFile: boolean;
  show: boolean;
}

const ELEMENT_DATA: ReportElement[] = [
  { position: 1, name: 'รายงานสรุปเรื่องร้องเรียนและข้อเสนอแนะตามหน่วยงาน', type: 1, description: 'สรุปจำนวนการร้องเรียนประจำเดือนโดยแบ่งตามหน่วยงาน และแยกเป็นสถานะต่างๆ เช่น ร้องเรียนใหม่ คำแนะนำ เป็นต้น', ReportFileName: 'Report_Complaint', FXViewer: true, LoadPDFFile: true ,show: true },
  { position: 2, name: 'รายงานสรุปเรื่องร้องเรียนและข้อเสนอแนะตามโครงการ', type: 1, description: 'สรุปจำนวนการร้องเรียนประจำเดือนโดยแบ่งตามโครงการ และแยกเป็นสถานะต่างๆ เช่น ร้องเรียนใหม่ คำแนะนำ เป็นต้น', ReportFileName: 'Report_Complaint_Project', FXViewer: true, LoadPDFFile: true, show: true },
  { position: 3, name: 'รายงานสรุปความพึงพอใจการใช้ระบบ', type: 1, description: 'สรุปความพึงพอใจการใช้ระบบ โดยจำแนกตามระดับ 1 - 5', ReportFileName: 'Report_Complacence', FXViewer: true, LoadPDFFile: true, show: false },
];

@Component({
    selector: 'app-reportcomplaint',
    templateUrl: './reportcomplaint.component.html',
    styleUrls: ['./reportcomplaint.component.scss'],
    animations: fuseAnimations
})
/** reportcomplaint component*/
export class ReportcomplaintComponent {
    /** reportcomplaint ctor */
  displayedColumns: string[] = ['position', 'name', 'description','ReportFileName'];
  dataSource = ELEMENT_DATA;
  constructor(public dialog: MatDialog, private httpClient: HttpClient) {

  }
  Save(data) {
    var applicationType = 'pdf';
    console.log(data);
    if (data.ReportFileName == 'Report_Complacence') {
      var applicationType = 'xls';
    }
    var chackfalse = false;
    var viewData = {
      ReportFileName: data.ReportFileName,
      ApplicationType: applicationType,
    };
    var param = $.param(viewData, true);
    var now = new Date();
    var now_time = now.getUTCFullYear() + "" + (now.getUTCMonth() + 1) + "" + (now.getUTCDate() + 1) + "-" + now.getHours() + "" + now.getUTCMinutes() + "" + now.getUTCSeconds();
    var apiData = { url: location.origin + "/api/report/" + data.ReportFileName + "?" + param, fileName: data.ReportFileName + "_" + now_time + "." + applicationType };
    this.httpClient.get(apiData.url, { responseType: 'arraybuffer' as 'json' }).subscribe(
      function successCallback(response: any) {
        chackfalse = true;
        if (applicationType == "pdf") {
          //var FileSaver = require('file-saver');
          var blob = new Blob([response], { type: 'application/pdf' });
          saveAs(blob, apiData.fileName);
        }
        if (applicationType == "xls") {
          var blob = new Blob([response], { type: 'application/vnd.ms-excel' });
          saveAs(blob, apiData.fileName);
        }
      },
      function errorCallback(response: any) {
        alert('เกิดข้อผิดพลาด ไม่สามารถออกรายงานได้');
      }
    )
  };
  Savepdf(data) {
    var applicationType = 'pdf';
    console.log(data);
    var chackfalse = false;
    var viewData = {
      ReportFileName: data.ReportFileName,
      ApplicationType: applicationType,
    };
    var param = $.param(viewData, true);
    var now = new Date();
    var now_time = now.getUTCFullYear() + "" + (now.getUTCMonth() + 1) + "" + (now.getUTCDate() + 1) + "-" + now.getHours() + "" + now.getUTCMinutes() + "" + now.getUTCSeconds();
    var apiData = { url: location.origin + "/api/report/" + data.ReportFileName + "?" + param, fileName: data.ReportFileName + "_" + now_time + "." + applicationType };
    this.httpClient.get(apiData.url, { responseType: 'arraybuffer' as 'json' }).subscribe(
      function successCallback(response: any) {
        chackfalse = true;
        if (applicationType == "pdf") {
          //var FileSaver = require('file-saver');
          var blob = new Blob([response], { type: 'application/pdf' });
          saveAs(blob, apiData.fileName);
        }
        if (applicationType == "xls") {
          var blob = new Blob([response], { type: 'application/vnd.ms-excel' });
          saveAs(blob, apiData.fileName);
        }
      },
      function errorCallback(response: any) {
        alert('เกิดข้อผิดพลาด ไม่สามารถออกรายงานได้');
      }
    )
  };
  openDialog(reportfile): void {
    const dialogRef = this.dialog.open(ReportfillterComponent, {
      //width: '250px',
      panelClass: 'myapp-no-padding-dialog',
      data: reportfile
    });

    dialogRef.afterClosed().subscribe(result => {
      //this.animal = result;
    });
  }
}
