import { Injectable, OnInit } from '@angular/core';
import { loadModules, loadScript } from 'esri-loader';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ModalmenucreateComponent } from '../../app/home/index/modalmenucreate/modalmenucreate.component';
import { MatSort, MatTableDataSource, MatPaginator, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
export class MapLayer {
  key: string;
  name: string;
  filter: number[] = [];
  visible: boolean;
  filterName: string;
  filterStreetName: string;
  filterProjectManagerName: string;
}
export class ArcgisApiService {

  loaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  mapLayers: any[] = [];

  constructor() {

    this.loaded$.subscribe(loaded => {
      if (!loaded) {
        loadScript({
          // use a specific version of the JSAPI
          url: 'https://js.arcgis.com/3.25/'
        })
          .then(() => {
            this.loaded$.next(true)
          }).catch(err => this.loaded$.next(true))
      }
    });
  }


  constructMap(opts: { container: string, basemap: any, center: any, zoom: number } ): Promise<any[]> {
    return new Promise((resolve, reject) => {
      loadModules(['esri/map',
        'dojo/domReady!',
        'esri/geometry/Point',
        'esri/layers/GraphicsLayer'
      ]).then(([Map, DomReady, Point, GraphicsLayer]) => {
        const map = new Map(opts.container, {
          basemap: opts.basemap,
          center: opts.center,
          zoom: opts.zoom
        });
        var point = new Point(100.531165, 13.719396);
        map.centerAndZoom(point, 13);

        var drawLayer = new GraphicsLayer();
        map.addLayer(drawLayer);

        resolve(map);
      });
    });
  }

  renderer(symbol: object, key: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      loadModules(['esri/renderers/ClassBreaksRenderer']).then(([ClassBreaksRenderer]) => {
        var render = new ClassBreaksRenderer(symbol, key);
        resolve(render);
      });
    });
  }

  openLayers(map, layers: Array<MapLayer>) {
    var _this = this;
    new Promise((resolve, reject) => {
      loadModules(['esri/renderers/ClassBreaksRenderer',
        'esri/Color',
        'esri/symbols/SimpleLineSymbol',
        'esri/symbols/PictureMarkerSymbol',
        'esri/symbols/SimpleMarkerSymbol']).then(([ClassBreaksRenderer, Color, SimpleLineSymbol, PictureMarkerSymbol, SimpleMarkerSymbol]) => {


          // ลบ Map layer ที่วาดก่อนหน้าทั้งหมด
          var mapLength = _this.mapLayers.length;
          if (mapLength > 0) {
            for (var j = mapLength - 1; j >= 0; j--) {
              map.removeLayer(_this.mapLayers[j]);
            }
          }

          layers.map(function (layer) {
            var mapLayer = _this.selectMapLayer(layer.name);

            if (!mapLayer)
              throw new Error("Layer not found!");
            mapLayer.then(mapLayer => {
              // mapLayer.FeatureLayer.setDefinitionExpression("");
              mapLayer.FeatureLayer.on("click", (evt) => _this.showTooltip(map, evt));
              //mapLayer.FeatureLayer.on("mouse-out", (evt) => _this.closeDialog(map, evt));

              // var queryText = [];
              // if (layer.filter.length > 0) {
              //   queryText.push(layer.key + " IN " + "(" + layer.filter.join(",") + ")");
              // }
              // if (queryText.length > 0) {
              //   mapLayer.FeatureLayer.setDefinitionExpression(queryText.join(" AND "));
              // }
              if (layer.filterName != "")
                mapLayer.FeatureLayer.setDefinitionExpression("Name Like '%" + layer.filterName + "%'");
              switch (layer.name) {
                case 'projectLocation':
                  var symbol = new PictureMarkerSymbol({
                    "url": "assets/images/point_icon/location-pin7.png",
                    "height": 26,
                    "width": 26,
                    "yoffset": 13
                  });
                  mapLayer.FeatureLayer.setRenderer(new ClassBreaksRenderer(symbol))
                  break;
                case 'projectDistance':
                  var symbol = new SimpleLineSymbol(SimpleLineSymbol.STYLE_SOLID, new Color("#bf2323"), 3);
                  mapLayer.FeatureLayer.setRenderer(new ClassBreaksRenderer(symbol));
                  break;
                default:
                  break;
              }


              mapLayer.FeatureLayer.setVisibility(layer.visible);

              if (layer.visible) {
                map.addLayer(mapLayer.FeatureLayer);
                _this.mapLayers.push(mapLayer.FeatureLayer);
              }

            });
          });
        });
    });
  }

  selectMapLayer(name: string): Promise<any> {
    var mapServerUrl = `${location.origin}/proxy/pmsmap/arcgis/rest/services/misMap/MapServer/`;
    return new Promise((resolve, reject) => {
      loadModules(['esri/layers/FeatureLayer',
        'esri/symbols/PictureMarkerSymbol',
        'esri/symbols/SimpleMarkerSymbol',
        'esri/Color',
        'esri/symbols/SimpleLineSymbol'
      ]).then((
        [FeatureLayer, PictureMarkerSymbol, SimpleMarkerSymbol, Color, SimpleLineSymbol]) => {
        switch (name) {
          case 'DistrictArea':
            resolve({
              FeatureLayer: new FeatureLayer(mapServerUrl + "5", {
                opacity: 0.4,
                outFields: ["*"],
                id: 'DistrictAreaLayer'
              })
            });
            break;
          case 'projectLocation':
            resolve({
              FeatureLayer: new FeatureLayer(mapServerUrl + "6", {
                outFields: ["*"],
                id: 'projectLocation'
              })
            });
            break;
          case 'projectDistance':
            resolve({
              FeatureLayer: new FeatureLayer(mapServerUrl + "7", {
                outFields: ["*"],
                id: 'projectDistance',
                Symbol: new SimpleLineSymbol(SimpleLineSymbol.STYLE_SOLID, new Color("#bf2323"), 3)
              })
            });
            break;
          default:
            resolve(false);
            break;
        }
      });
    });
  }


  showTooltip(map, evt) {

    if (evt.graphic._layer.id == 'projectLocation' ||
      evt.graphic._layer.id == 'projectDistance') {
      console.log(evt.graphic.attributes)
      var content = '<div><span><b>ชื่อโครกงาร : </b></span>' + evt.graphic.attributes.Name + '</div>' +
        '<div><span><b>ชื่อผู้ดุแลโครงการ : </b></span>' + evt.graphic.attributes.ProjectManagerName + '</div>' +
        '<div><span><b>รหัสงบประมาณ : </b></span>' + evt.graphic.attributes.BudgetCode + '</div>' +
        '<div><span><b>เป้าหมาย : </b></span>' + evt.graphic.attributes.Target + '</div>' +
        '<div><span><b>วัตถุประสงค์ : </b></span>' + evt.graphic.attributes.Objective + '</div>'+
        '<button mat-raised-button color="accent" (click)="openDialog()">เพิ่มคำร้องเรียนจากตำแหน่งบนแผนที่</button>'
        ;
      map.infoWindow.setTitle("โครงการก่อสร้าง");
      map.infoWindow.setContent(content);
      map.infoWindow.show(evt.screenPoint, map.getInfoWindowAnchor(evt.screenPoint));
    }
  }

  //closeDialog(map, evt) {
  //  map.infoWindow.hide();
  //}
}
