import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { FormControl, Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatSort, MatTableDataSource, MatPaginator, MatFormFieldModule, MatInputModule } from '@angular/material';
import { SuccessdialogComponent } from '../../../handledialog/successdialog/successdialog.component';
import { fuseAnimations } from '../../../../@fuse/animations';
import { FuseConfirmDialogComponent } from '../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import { HttpClient, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-modalfiltermap',
  templateUrl: './modalfiltermap.component.html',
  styleUrls: ['./modalfiltermap.component.scss'],
  animations: fuseAnimations
})

/** modalmenucreate component*/
export class ModalfiltermapComponent implements OnInit {

  
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ModalfiltermapComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private _formBuilder: FormBuilder,
    public _matDialog: MatDialog,
    private _httpClient: HttpClient
  ) {
  }

  ngOnInit(): void {
    console.log("hellot world")
  }

  checkPosition(event) {
    console.log(event)
  }

  checkLine(event) {
    console.log(event)
  }

  submit() {
    this.dialogRef.close(this.data.filterLayerMap)
  }
}
