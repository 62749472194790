import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatSort, MatTableDataSource, MatPaginator, MatFormFieldModule, MatInputModule } from '@angular/material';
import { fuseAnimations } from '../../../../src/@fuse/animations';
import { HttpClient, HttpEventType, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { saveAs } from 'file-saver';
import * as fileSaver from 'file-saver';
import * as $ from "jquery";
import { ErrordialogComponent } from '../../handledialog/errordialog/errordialog.component';
@Component({
  selector: 'app-reportfillter',
  templateUrl: './reportfillter.component.html',
  styleUrls: ['./reportfillter.component.scss']
})
/** reportfillter component*/
export class ReportfillterComponent {
  /** reportfillter ctor */
  fillter: FormGroup;
  constructor(public dialogRef: MatDialogRef<ReportfillterComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private _formBuilder: FormBuilder, private httpClient: HttpClient, public dialog: MatDialog) {
  }
  ngOnInit(): void {
    var a = new Date();
    //let year = now.getFullYear();
    //let month = now.getMonth() + 1;
    //let day = new Date(year, month, 0).getDate();
   // this.toDate = { year: year, month: month, day: day };
    var atart = new Date(a.getUTCFullYear(), a.getMonth() , 1);
    var aend = new Date(a.getUTCFullYear(), a.getMonth() + 1, 0);
    this.fillter = this._formBuilder.group({
      start: [atart],
      end: [aend],
    });
  }
  Save() {
    var applicationType = 'pdf';
    var send = this.fillter.getRawValue();
    var test2 = send.start.getUTCFullYear() + "-" + (send.start.getMonth() + 1) + "-" + send.start.getDate();
    var test3 = send.end.getUTCFullYear() + "-" + (send.end.getUTCMonth() + 1) + "-" + send.end.getDate();
    var chackfalse = false;
    var viewData = {
      ReportFileName: this.data,
      ApplicationType: applicationType,
      Start: test2,
      End: test3
    };
    var param = $.param(viewData, true);
    var now = new Date();
    var now_time = now.getUTCFullYear() + "" + (now.getUTCMonth() + 1) + "" + (now.getUTCDate() + 1) + "-" + now.getHours() + "" + now.getUTCMinutes() + "" + now.getUTCSeconds();
    var apiData = { url: location.origin + "/api/report/" + this.data + "?" + param, fileName: this.data + "_" + now_time + "." + applicationType };
    this.httpClient.get(apiData.url, { responseType: 'arraybuffer' as 'json' }).subscribe(
      function successCallback(response: any) {
        chackfalse = true;
        if (applicationType == "pdf") {
          //var FileSaver = require('file-saver');
          var blob = new Blob([response], { type: 'application/pdf' });
          saveAs(blob, apiData.fileName);
        }
      },
      function errorCallback(response: any) {
        alert('เกิดข้อผิดพลาด ไม่สามารถออกรายงานได้');
      }
    )
  };
}
