import { Component, OnInit, ViewChild, NgModule, Inject } from '@angular/core';
import { ArcgisApiService, MapLayer } from '../../services';
import { ApiService } from '../../services/api.service';
import { MyService } from '../../services/my.service';
import { } from '../../services';
import { fuseAnimations } from '@fuse/animations';
import { MatSort, MatTableDataSource, MatPaginator, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Routes, RouterModule } from '@angular/router';
//import { ModalmainlistComponent } from './modalmainlist/modalmainlist.component';
import { ModalmenucreateComponent } from './modalmenucreate/modalmenucreate.component';
import { from } from 'rxjs';
import { ModalmainlistComponent } from '../index/modalmainlist/modalmainlist.component';
import { ComplaintendlistComponent } from '../index/complaintendlist/complaintendlist.component';
import { RatingcheckComponent } from '../../handledialog/ratingcheck/ratingcheck.component';
import { AuthenticationService } from 'app/services/authentication.service';
import { DataSource } from '@angular/cdk/table';
import { DatePipe, formatDate, Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalfiltermapComponent } from './modalfiltermap/modalfiltermap.component';

import { HttpClient } from '@angular/common/http';

export interface Food {
  value: number;
  viewValue: string;
}

export interface DialogData {
  animal: string;
  name: string;
}

export interface FilterLayerMap {
  projectLocation: boolean
  projectDistance: boolean
  projectPublicutilityLocation: boolean
  projectPublicutilityDistance: boolean
  projectManholeCoverLocation: boolean
  projectInConstructionBuildings: boolean
}

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css'],
  animations: fuseAnimations
})
//@NgModule({
//  declarations: [
//    ModalmainlistComponent,
//    CheckcomplaintuserComponent
//  ],
//  imports: [

//    RouterModule.forRoot([

//    ])
//  ],
//    providers: [ArcgisApiService],
//    bootstrap: [IndexComponent]
//})
export class IndexComponent implements OnInit {
  public Project;
  selectedValue: number;
  currentUsername: string;
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['Position', 'Name', 'DepartmentName'];
  foods: Food[] = [
    { value: 1, viewValue: 'รอการชี้แจง' },
    { value: 2, viewValue: 'กำลังดำเนินการแก้ไข' },
    { value: 3, viewValue: 'แจ้งผล' },
    { value: 4, viewValue: 'ปิดเรื่อง' }
  ];
  size = 16;
  map: any;
  public searchTime = {
    startTime: "",
    finishTime: "",
  };

  widgets = {
    widget8: {
      scheme: {
        domain: ['#5c84f1']
      },
      today: '12,540',
      change: {
        value: 321,
        percentage: 2.05
      },
      data: [
        {
          name: 'Sales',
          series: [
            {
              name: 'Jan 1',
              value: 540
            },
            {
              name: 'Jan 2',
              value: 539
            },
            {
              name: 'Jan 3',
              value: 538
            },
            {
              name: 'Jan 4',
              value: 539
            },
            {
              name: 'Jan 5',
              value: 540
            },
            {
              name: 'Jan 6',
              value: 539
            },
            {
              name: 'Jan 7',
              value: 540
            }
          ]
        }
      ],
      dataMin: 538,
      dataMax: 541
    },
    widget9: {
      rows: [
        {
          title: 'Holiday Travel',
          clicks: 3621,
          conversion: 90
        },
        {
          title: 'Get Away Deals',
          clicks: 703,
          conversion: 7
        },
        {
          title: 'Airfare',
          clicks: 532,
          conversion: 0
        },
        {
          title: 'Vacation',
          clicks: 201,
          conversion: 8
        },
        {
          title: 'Hotels',
          clicks: 94,
          conversion: 4
        }
      ]
    }
  };
  widget1SelectedYear = '2016';
  widget5SelectedDay = 'today';

  filterLayerMap: FilterLayerMap = {
    projectDistance: false,
    projectLocation: false,
    projectPublicutilityDistance: true,
    projectPublicutilityLocation: true,
    projectManholeCoverLocation: true,
    projectInConstructionBuildings: false
  }

  listYear: number[] = [];
  year: number = 2023;
  constructor(
    private apiService: ApiService,
    private arcgisService: MyService,
    public dialog: MatDialog,
    public _matdialograting: MatDialog,
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private http: HttpClient
  ) {
    this.currentUsername = this.authenticationService.currentUserValue ? this.authenticationService.currentUserValue.username : "Guest";
    this.listYear = this.generateArrayOfYears();
  }

  openDialog(data): void {
    const dialogRef = this.dialog.open(ModalmenucreateComponent, {
      panelClass: 'myapp-no-padding-dialog',
      data: {
        //Id: id,
        //Long: position.x,
        //Lat: position.y,
        //ProjectManagerName: evt.graphic.attributes.ProjectManagerName,
        //Designer: evt.graphic.attributes.Designer,
        //Supervisor: evt.graphic.attributes.Supervisor,
        //Name: evt.graphic.attributes.Name
        Id: data.OriginalPlanId,
        Name: data.Name
      }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }
  openDialogRating(): void {
    const ratinfRef = this._matdialograting.open(RatingcheckComponent, {
      panelClass: 'myapp-no-padding-dialog',
      data: {}
    });

    ratinfRef.afterClosed().subscribe(result => {

    });
  }
  projectLocation = new MapLayer();
  projectDistance = new MapLayer();
  projectPublicutilityLocation = new MapLayer();
  projectPublicutilityDistance = new MapLayer();
  projectManholeCoverLocation = new MapLayer();
  projectInConstructionBuildings = new MapLayer();

  ngOnInit() {

    this.arcgisService.loaded$.subscribe(loaded => {
      if (loaded) {
        this.arcgisService
          .constructMap({
            basemap: "topo",
            container: "map",
            center: [1, 45],
            zoom: 5
          })
          .then(map => {
            this.map = map;
            // this.projectLocation.name = "projectLocation";
            // this.projectLocation.visible = true;
            // this.projectLocation.filterName="";

            // this.projectDistance.name = "projectDistance";
            // this.projectDistance.visible = true;
            // this.projectDistance.filterName = "";

            this.projectPublicutilityLocation.name = "projectPublicutilityLocation";
            this.projectPublicutilityLocation.visible = true;
            this.projectPublicutilityLocation.filterName = "";
            this.projectPublicutilityLocation.filterStreetName = "";

            this.projectPublicutilityDistance.name = "projectPublicutilityDistance";
            this.projectPublicutilityDistance.visible = true;
            this.projectPublicutilityDistance.filterName = "";
            this.projectPublicutilityDistance.filterStreetName = "";

            this.projectManholeCoverLocation.name = "projectManholeCoverLocation";
            this.projectManholeCoverLocation.visible = true;
            this.projectManholeCoverLocation.filterName = "";
            this.projectManholeCoverLocation.filterStreetName = "";

            this.projectInConstructionBuildings.name = "projectInConstructionBuildings";
            this.projectInConstructionBuildings.visible = false;
            this.projectInConstructionBuildings.filterName = "";
            this.projectInConstructionBuildings.filterStreetName = "";

            this.arcgisService.openLayers(this.map, [this.projectInConstructionBuildings, this.projectPublicutilityLocation, this.projectPublicutilityDistance, this.projectManholeCoverLocation], this.year);

            this.apiService.getOriginalPlan().subscribe((data: object[]) => {
              this.Project = data;
              this.dataSource = new MatTableDataSource(this.Project.Results);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              // this.dataSource.filterPredicate = (data: any, filter: string) => {
              //   const year = new Date(data.date).getFullYear().toString();
              //   return year === filter;
              // };
              // const desiredYear = '2023';
              // this.dataSource.filter = desiredYear;
              // console.log(this.dataSource,'filter');

              this.route.queryParams.subscribe(params => {
                if (params['instantComplaintID']) {
                  this.location.replaceState('/main');

                  this.Project.Results.forEach(obj => {
                    if (obj.OriginalPlanId == Number(params['instantComplaintID'])) {
                      this.openDialog(obj);
                    }
                  });
                }
              });

            });

          });
      }
    });

    //this.openDialogRating();
  }

  generateArrayOfYears(): number[] {
    let max = new Date().getFullYear();
    let min = max - 4;
    let years = [];

    for (let i = max; i >= min; i--) {
      years.push(i);
    }
    return years;
  }

  onClickMe = function () {
    this.searchTime.startTime = new Date();
    this.filterLayerMap.projectManholeCoverLocation = true;
    this.projectPublicutilityDistance.filterName = this.projectPublicutilityLocation.filterName;
    this.projectPublicutilityDistance.filterStreetName = this.projectPublicutilityLocation.filterStreetName;

    this.arcgisService.openLayers(this.map, [this.projectPublicutilityLocation, this.projectPublicutilityDistance], this.year);

    this.dataSource.filter = this.projectPublicutilityLocation.filterName.trim().toLocaleLowerCase();

    console.log(this.dataSource, "onClickMe");
    // this.dataSource.paginator.firstPage();

    this.searchTime.finishTime = new Date();

    // this.apiService.saveSearchTime(this.searchTime).subscribe((response) => {
    // });

  }

  openFilterModal = function () {
    const dialogRef = this.dialog.open(ModalfiltermapComponent, {
      panelClass: 'myapp-no-padding-dialog',
      data: {
        filterLayerMap: this.filterLayerMap
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("OUT!!")
      console.log(result)

      if (result) {
        this.filterLayerMap = result

        let layers = [];

        if (this.filterLayerMap.projectLocation) {
          layers.push(this.projectLocation)
        }

        if (this.filterLayerMap.projectDistance) {
          layers.push(this.projectDistance)
        }

        if (this.filterLayerMap.projectPublicutilityLocation) {
          this.projectPublicutilityLocation.name = "projectPublicutilityLocation";
          this.projectPublicutilityLocation.visible = true;
          this.projectPublicutilityLocation.filterName = "";

          layers.push(this.projectPublicutilityLocation)
        }

        if (this.filterLayerMap.projectPublicutilityDistance) {
          this.projectPublicutilityDistance.name = "projectPublicutilityDistance";
          this.projectPublicutilityDistance.visible = true;
          this.projectPublicutilityDistance.filterName = "";

          layers.push(this.projectPublicutilityDistance)
        }

        if (this.filterLayerMap.projectManholeCoverLocation) {
          this.projectManholeCoverLocation.name = "projectManholeCoverLocation";
          this.projectManholeCoverLocation.visible = true;
          this.projectManholeCoverLocation.filterName = "";
          this.projectManholeCoverLocation.filterStreetName = "";

          layers.push(this.projectManholeCoverLocation);
        }
        if (this.filterLayerMap.projectInConstructionBuildings) {
          this.projectInConstructionBuildings.name = "projectInConstructionBuildings";
          this.projectInConstructionBuildings.visible = true;
          this.projectInConstructionBuildings.filterName = "";

          layers.push(this.projectInConstructionBuildings)
        }

        this.arcgisService.openLayers(this.map, layers, this.year);
      }
    });
  }

  tableClick = function (originalPlan) {
    console.log(originalPlan);

  }

  onYearChange(year: number) {
    console.log('Selected year:', year);
    this.year = year;
    this.filterLayerMap.projectManholeCoverLocation = true;
    this.arcgisService.openLayers(this.map, [this.projectInConstructionBuildings, this.projectPublicutilityLocation, this.projectPublicutilityDistance, this.projectManholeCoverLocation], this.year);
  }
}
