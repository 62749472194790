import { Component, OnInit, ViewChild, NgModule, OnDestroy } from '@angular/core';
import { ArcgisApiService, MapLayer } from '../../../services';
import { MatSort, MatTableDataSource, MatPaginator, MatFormFieldModule, MatInputModule } from '@angular/material';
import { Routes, RouterModule } from '@angular/router';
import { ApiService } from '../../../services/api.service';
import { fuseAnimations } from '../../../../@fuse/animations';
import { MatDialog } from '@angular/material';
import { SuggesinfoComponent } from '../suggesinfo/suggesinfo.component';

export interface SuggesttionElement {
  suggestionId: number;
  nameSuggestion: string;
  nameProject: string;
  c_DepartmentName: string;
  suggestionDate: Date;
}

@Component({
    selector: 'app-suggestlist',
    templateUrl: './suggestlist.component.html',
    styleUrls: ['./suggestlist.component.scss'],
    animations: fuseAnimations
})
/** suggestlist component*/
export class SuggestlistComponent implements OnDestroy {
  public listsugges: SuggesttionElement[];
  displayedColumns: string[] = ['position', 'nameSuggestion', 'nameProject', 'c_DepartmentName', 'suggestionDate', 'suggestionId'];
  dataSource = new MatTableDataSource();
  selectedValue: number;
  fillterName: string;
  fillterNameProject: string;
  fillterNameComplaint: string;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(private apiService: ApiService, public dialog: MatDialog) {

  }

  openDetail(Id) {
    const dialogRef = this.dialog.open(SuggesinfoComponent, {
      panelClass: 'myapp-no-padding-dialog',
      data: {
        Id: Id,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getSuggestlist();
    });
  }
  ngOnInit() {
    this.getSuggestlist();
  }
  public getSuggestlist() {
    this.apiService.getSuggestlist().subscribe((data: SuggesttionElement[]) => {
      this.listsugges = data;
      this.dataSource = new MatTableDataSource(this.listsugges);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.filterPredicate =
        (data: SuggesttionElement, filtersJson: string) => {
          const matchFilter = [];
          const filters = JSON.parse(filtersJson);

          filters.forEach(filter => {
            if (filter.idInner == null) {
              const val = data[filter.id] === null ? '' : data[filter.id];
              matchFilter.push(val.toLowerCase().includes(filter.value.toLowerCase()));
            }
            else {
              const val = data[filter.id][filter.idInner] === null ? '' : data[filter.id][filter.idInner];
              matchFilter.push(val.toLowerCase().includes(filter.value.toLowerCase()));
            }

          });
          return matchFilter.every(Boolean);
        };
    });
  }
  public doFilter = (value: string, column: string, inner: string) => {

    const tableFilters = [];
    tableFilters.push({
      id: column,
      idInner: inner,
      value: value
    });

    this.dataSource.filter = JSON.stringify(tableFilters);

    //this.dataSource.filter = value.trim().toLocaleLowerCase();
    this.dataSource.paginator.firstPage();
  }

  ngOnDestroy(): void {
    this.apiService.getSuggestlist();
  }
}
