import { Injectable, OnInit } from '@angular/core';
import { loadModules, loadScript } from 'esri-loader';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ModalmenucreateComponent } from '../../app/home/index/modalmenucreate/modalmenucreate.component';
import { CreatesuggestionComponent } from '../../app/home/index/createsuggestion/createsuggestion.component';
import { MatSort, MatTableDataSource, MatPaginator, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SuccessdialogComponent } from '../../app/handledialog/successdialog/successdialog.component';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';

export class MapLayer {
  key: string;
  name: string;
  filter: number[] = [];
  visible: boolean;
  filterName: string;
  filterStreetName: string;
  filterProjectManagerName: string;
}
export interface DialogData {
  Id: number;
}
@Injectable()
export class MyService {
  loaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  mapLayers: any[] = [];
  public model;
  constructor(public dialog: MatDialog, public mat_dialog: MatDialog, private apiService: ApiService, private http: HttpClient) {
    this.loaded$.subscribe(loaded => {
      if (!loaded) {
        loadScript({
          // use a specific version of the JSAPI
          url: 'https://js.arcgis.com/3.25/'
        })
          .then(() => {
            this.loaded$.next(true)
          }).catch(err => this.loaded$.next(true))
      }
    });
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(ModalmenucreateComponent, {
      width: '1500px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }
  openSuggesDialog(): void {
    const dialogRefsugges = this.mat_dialog.open(CreatesuggestionComponent, {
      width: '1500px',
      data: {}
    });

    dialogRefsugges.afterClosed().subscribe(result => {

    });
  }
  constructMap(opts: { container: string, basemap: any, center: any, zoom: number }): Promise<any[]> {
    return new Promise((resolve, reject) => {
      loadModules([
        'esri/map',
        'dojo/domReady!',
        'esri/geometry/Point',
        'esri/layers/GraphicsLayer',
        // 'esri/widgets/Locate'
        'esri/dijit/LocateButton',
        'esri/symbols/PictureMarkerSymbol',
        'esri/dijit/Scalebar'
      ]).then(([
          Map,
          DomReady,
          Point,
          GraphicsLayer,
          // Locate
          LocateButton,
          PictureMarkerSymbol,
          Scalebar,
        ]) => {
        const map = new Map(opts.container, {
          basemap: opts.basemap,
          center: opts.center,
          zoom: opts.zoom
        });

        var scalebar = new Scalebar({
          map: map,
          attachTo: "bottom-left"
        });

        scalebar.show();

        var point = new Point(100.531165, 13.719396);
        map.centerAndZoom(point, 13);

        var drawLayer = new GraphicsLayer();
        map.addLayer(drawLayer);

        // Create a new LocateButton widget
        var locateButton = new LocateButton({
            theme: "LocateButton",
            map: map,
            visible: true,
            highlightLocation: true,
            scale: 10000,
            tracking: true,
            useTracking: false,
            setScale: false,
            domNode: 'locateButton',
            geolocationOptions: {
              maximumAge: 0,
              timeout: 15000,
              enableHighAccuracy: true
            },
        }, 'locateButton');

        const locateButtonLabel = document.getElementById('locateButtonLabel');
        locateButtonLabel.addEventListener('click', () => {
          locateButton.locate();
        });

        // Start the widget
        locateButton.startup();

        resolve(map);
      });
    });
  }

  renderer(symbol: object, key: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      loadModules(['esri/renderers/ClassBreaksRenderer']).then(([ClassBreaksRenderer]) => {
        var render = new ClassBreaksRenderer(symbol, key);
        resolve(render);
      });
    });
  }

  setPosition(map, x, y) {

    new Promise((resolve, reject) => {
      loadModules(['esri/map',
        'dojo/domReady!',
        'esri/geometry/Point',
        'esri/layers/GraphicsLayer'
      ]).then(([Map, DomReady, Point, GraphicsLayer]) => {

        var point = new Point(x, y);
        map.centerAndZoom(point, 18);

        });
    });
  }


  openLayers(map, layers: Array<MapLayer>,years: number) {
    var _this = this;
    new Promise((resolve, reject) => {
      loadModules(['esri/renderers/ClassBreaksRenderer',
        'esri/Color',
        'esri/symbols/SimpleLineSymbol',
        'esri/symbols/PictureMarkerSymbol',
        'esri/symbols/SimpleMarkerSymbol',
        "dojo/_base/array"
      ]).then(([ClassBreaksRenderer, Color, SimpleLineSymbol, PictureMarkerSymbol, SimpleMarkerSymbol, array]) => {

          // ลบ Map layer ที่วาดก่อนหน้าทั้งหมด
          var mapLength = _this.mapLayers.length;
          if (mapLength > 0) {
            for (var j = mapLength - 1; j >= 0; j--) {
              map.removeLayer(_this.mapLayers[j]);
            }
          }

          layers.map(function (layer) {
            var mapLayers = _this.selectMapLayer(layer.name);

            if (!mapLayers){
              throw new Error("Layer not found!");
            }

            mapLayers.then(mapLayer => {
              mapLayer.FeatureLayer.on("click", (evt) => _this.showTooltip(map, evt));
              // mapLayer.FeatureLayer.setDefinitionExpression("");


              // _this.filterStatus(map, evt);

              // var queryText = [];
              // if (layer.filter.length > 0) {
              //   queryText.push(layer.key + " IN " + "(" + layer.filter.join(",") + ")");
              // }
              // if (queryText.length > 0) {
              //   mapLayer.FeatureLayer.setDefinitionExpression(queryText.join(" AND "));
              // }


              switch (layer.name) {
                case 'projectLocation':
                  var symbol = new PictureMarkerSymbol({
                    "url": "assets/images/point_icon/iconcontract.png",
                    "height": 35,
                    "width": 60,
                    "yoffset": 13
                  });
                  mapLayer.FeatureLayer.setRenderer(new ClassBreaksRenderer(symbol))
                  break;
                case 'projectPublicutilityLocation':
                  var queries = [layer.filterName && "NameSubject Like N'%" + layer.filterName + "%'","NameSubject Like N'%" + layer.filterStreetName + "%'", "CreatedDate>='"+ years + "'and CreatedDate<'"+(years+1)+"'"]
                  var query = queries.filter(query => !!query).join(' AND ')
                  mapLayer.FeatureLayer.setDefinitionExpression(query);

                  mapLayer.FeatureLayer.on("update-end", (evt) => {

                    var symbol = new PictureMarkerSymbol({
                      "url": "",
                      "height": 26,
                      "width": 26,
                      "yoffset": 13
                    });

                    var approve = new PictureMarkerSymbol({
                      "url": "assets/images/point_icon/pin_Green.png",
                      "height": 26,
                      "width": 26,
                      "yoffset": 13
                    });

                    var pending = new PictureMarkerSymbol({
                      "url": "assets/images/point_icon/pin_Yellow.png",
                      "height": 26,
                      "width": 26,
                      "yoffset": 13
                    });

                    var  renderer = new ClassBreaksRenderer(symbol,function(item) {return item.attributes.PublicUtilityRequestId})

                    _this.apiService.getAllStatus(years).subscribe(
                    (data: any) => {
                      for (var i = 0; i < data.approvals.length; i++)
                      {
                        renderer.addBreak({
                          minValue:data.approvals[i],
                          maxValue:data.approvals[i],
                          symbol:approve,
                        });
                      }
                      for (var x = 0; x < data.pendingapprovals.length; x++)
                      {
                        renderer.addBreak({
                          minValue:data.pendingapprovals[x],
                          maxValue:data.pendingapprovals[x],
                          symbol:pending,
                        });
                      }
                      for (var z = 0; z < data.notapprovals.length; z++)
                      {
                        renderer.addBreak({
                          minValue:data.notapprovals[z],
                          maxValue:data.notapprovals[z],
                          symbol:symbol,
                        });
                      }
                    mapLayer.FeatureLayer.setRenderer(renderer);
                    mapLayer.FeatureLayer.refresh();
                    },
                    (error) => {
                      console.error('An error occurred:', error);
                    });

                  });
                  break;
                case 'projectDistance':
                  var symbol = new SimpleLineSymbol(SimpleLineSymbol.STYLE_SOLID, new Color("#bf2323"), 3);
                  mapLayer.FeatureLayer.setRenderer(new ClassBreaksRenderer(symbol));
                  break;
                case 'projectPublicutilityDistance':
                  var symbol = new SimpleLineSymbol(SimpleLineSymbol.STYLE_SOLID, new Color("#a0bf23"), 3)
                  mapLayer.FeatureLayer.setRenderer(new ClassBreaksRenderer(symbol));
                  break;
                case 'projectInConstructionBuildings':
                  // ไม่ต้องปรับอะไรใช้ข้อมูลจาก kml ได้เลย
                  break;
                case 'projectManholeCoverLocation':
                  // ไม่ต้องปรับอะไรใช้ข้อมูลจาก kml ได้เลย
                  break;
                default:
                  break;
              }

              mapLayer.FeatureLayer.setVisibility(layer.visible);

              if (layer.visible) {
                map.addLayer(mapLayer.FeatureLayer);
                _this.mapLayers.push(mapLayer.FeatureLayer);
              }

              console.log(mapLayer.FeatureLayer);

            });
          });
        });
    });
  }

  selectMapLayer(name: string): Promise<any> {
    var mapServerUrl = `${location.origin}/proxy/pmsmap/arcgis/rest/services/misMap/MapServer/`;
    return new Promise((resolve, reject) => {
      loadModules(['esri/layers/FeatureLayer',
        'esri/layers/KMLLayer',
        'esri/symbols/PictureMarkerSymbol',
        'esri/symbols/SimpleMarkerSymbol',
        'esri/Color',
        'esri/symbols/SimpleLineSymbol'
      ]).then((
        [FeatureLayer, KMLLayer, PictureMarkerSymbol, SimpleMarkerSymbol, Color, SimpleLineSymbol]) => {

        switch (name) {
          case 'projectManholeCoverLocation':
            resolve({
              FeatureLayer: new KMLLayer(location.origin + '/assets/kml/manhole.txt', {
                outFields: ["*"],
                id: 'projectManholeCoverLocation',
              })
            });
            break;
          case 'projectInConstructionBuildings':
            resolve({
              FeatureLayer: new KMLLayer(location.origin + '/assets/kml/projectInConstructionBuildings.txt', {
                outFields: ["*"],
                id: 'projectInConstructionBuildings',
              })
            });
            break;
          case 'DistrictArea':
            resolve({
              FeatureLayer: new FeatureLayer(mapServerUrl + "5", {
                opacity: 0.4,
                outFields: ["*"],
                id: 'DistrictAreaLayer'
              })
            });
            break;
          case 'projectLocation':
            resolve({
              FeatureLayer: new FeatureLayer(mapServerUrl + "6", {
                outFields: ["*"],
                id: 'projectLocation'
              })
            });
            break;
          case 'projectDistance':
            resolve({
              FeatureLayer: new FeatureLayer(mapServerUrl + "7", {
                outFields: ["*"],
                id: 'projectDistance',
                Symbol: new SimpleLineSymbol(SimpleLineSymbol.STYLE_SOLID, new Color("#bf2323"), 3)
              })
            });
            break;
          case 'projectPublicutilityLocation':
            var FeatureLayer =  new FeatureLayer(mapServerUrl + "30", {
              outFields: ["*"],
              id: 'projectPublicutilityLocation'
            })
            console.log(FeatureLayer.graphics);
            resolve({
              FeatureLayer : FeatureLayer
            });
            break;
          case 'projectPublicutilityDistance':
            resolve({
              FeatureLayer: new FeatureLayer(mapServerUrl + "31", {
                outFields: ["*"],
                id: 'projectPublicutilityDistance',
                Symbol: new SimpleLineSymbol(SimpleLineSymbol.STYLE_SOLID, new Color("#a0bf23"), 3)
              })
            });
            break;
          default:
            resolve(false);
            break;
        }
      });
    });
  }

  showTooltip(map, evt) {
    //this.openDialog();
    return new Promise((resolve, reject) => {
      loadModules(['esri/geometry/webMercatorUtils'
      ]).then((
        [webMercatorUtils]) => {
        var position = webMercatorUtils.webMercatorToGeographic(evt.graphic.geometry);
        if (evt.graphic._layer.id == 'projectLocation' ||
          evt.graphic._layer.id == 'projectDistance') {
          if (evt.graphic.attributes.Name == null) {
            evt.graphic.attributes.Name = '';
          }
          if (evt.graphic.attributes.ProjectManagerName == null) {
            evt.graphic.attributes.ProjectManagerName = '-';
          }
          if (evt.graphic.attributes.BudgetCode == null) {
            evt.graphic.attributes.BudgetCode = '-';
          }
          if (evt.graphic.attributes.Target == null) {
            evt.graphic.attributes.Target = '-';
          }
          if (evt.graphic.attributes.Objective == null) {
            evt.graphic.attributes.Objective = '-';
          }

          this.apiService.getPathPic(evt.graphic.attributes.OriginalPlanId).subscribe((data: object[]) => {
            //this.model = data;
            var mapMIS = "https://misservice2.ddns.net";
            var urlimg = mapMIS + data;
            var content = '<div style="font-size:15px;"><span><b>ชื่อโครงการ : </b></span>' + evt.graphic.attributes.Name + '</div>' +
              '<button style="margin-top:5px;background-color:#039be5;border: none;text-align: center;font-size: 12px;cursor: pointer;color:white;padding: 10px 20px;box-shadow: 1px 1px 1px 1px #888888;border-radius: 4px;" id="myImg"  onclick="openDialog()">เพิ่มคำร้องเรียน</button>' + ' ' +
              '<button style="margin-top:5px;background-color:#63B94D;border: none;text-align: center;font-size: 12px;cursor: pointer;color:white;padding: 10px 20px;box-shadow: 1px 1px 1px 1px #888888;border-radius: 4px;" id="sugges"  onclick="openSuggesDialog()">เพิ่มข้อเสนอแนะ</button>' +
              '<div style="margin-top:10px;"><span><b>ชื่อผู้ดุแลโครงการ : </b></span>' + evt.graphic.attributes.ProjectManagerName + '</div>' +
              '<div><span><b>รหัสงบประมาณ : </b></span>' + evt.graphic.attributes.BudgetCode + '</div>' +
              '<div><span><b>เป้าหมาย : </b></span>' + evt.graphic.attributes.Target + '</div>' +
              '<div><span><b>วัตถุประสงค์ : </b></span>' + evt.graphic.attributes.Objective + '</div>' +
              "<br><img id='myImg' alt='File' onerror='this.remove()' src='" + urlimg + "'  />"
              ;

            map.infoWindow.setTitle("โครงการก่อสร้าง");
            map.infoWindow.setContent(content);
            map.infoWindow.show(evt.screenPoint, map.getInfoWindowAnchor(evt.screenPoint));
            var img = document.getElementById('myImg');
            var sugges = document.getElementById('sugges');
            if (img) {
              img.onclick = () => {
                let dialogRef = this.dialog.open(ModalmenucreateComponent, {
                  panelClass: 'myapp-no-padding-dialog',
                  data: {
                    Id: evt.graphic.attributes.OriginalPlanId,
                    Long: position.x,
                    Lat: position.y,
                    ProjectManagerName: evt.graphic.attributes.ProjectManagerName,
                    Designer: evt.graphic.attributes.Designer,
                    Supervisor: evt.graphic.attributes.Supervisor,
                    Name: evt.graphic.attributes.Name
                  }
                });

                dialogRef.afterClosed().subscribe(result => {

                });
              }
            }
            if (sugges) {
              sugges.onclick = () => {
                let dialogRefsugges = this.mat_dialog.open(CreatesuggestionComponent, {
                  panelClass: 'myapp-no-padding-dialog',
                  data: {
                    Id: evt.graphic.attributes.OriginalPlanId,
                    Long: position.x,
                    Lat: position.y,
                    ProjectManagerName: evt.graphic.attributes.ProjectManagerName,
                    Designer: evt.graphic.attributes.Designer,
                    Supervisor: evt.graphic.attributes.Supervisor,
                    Name: evt.graphic.attributes.Name
                  }
                });

                dialogRefsugges.afterClosed().subscribe(result => {

                });
              }
            }
          });

        }
        else if (evt.graphic._layer.id == 'projectPublicutilityLocation' ||
          evt.graphic._layer.id == 'projectPublicutilityDistance') {

          this.apiService.getStatus(evt.graphic.attributes.PublicUtilityRequestId).subscribe(
            (data: any) => {
              if (evt.graphic.attributes.NameSubject == null) {
                evt.graphic.attributes.NameSubject = 'N/A';
              }
              if (evt.graphic.attributes.NameOfCoordinator == null) {
                evt.graphic.attributes.NameOfCoordinator = 'N/A';
              }
              if (evt.graphic.attributes.PermitCode == null) {
                evt.graphic.attributes.PermitCode = 'N/A';
              }
              if (evt.graphic.attributes.LocationId == null) {
                evt.graphic.attributes.LocationId = 'N/A';
              }
              if (data.ApprovalStatusName == null) {
                data.ApprovalStatusName = 'N/A';
              }
              if (evt.graphic.attributes.County == null) {
                evt.graphic.attributes.County = 'N/A';
              }
              if (data.PublicUtilityRequestCounties == null) {
                data.PublicUtilityRequestCounties = 'N/A';
              }

              // var content = '<div style="font-size:15px;"><span><b>ชื่อโครงการ : </b></span>' + evt.graphic.attributes.NameSubject + '</div>' +
              //   '<div style="margin-top:10px;"><span><b>ชื่อผู้ดุแลโครงการ : </b></span>' + evt.graphic.attributes.NameOfCoordinator + '</div>' +
              //   '<div><span><b>รหัสใบอนุญาต : </b></span>' + evt.graphic.attributes.PermitCode + '</div>' +
              //   '<div><span><b>รหัสตำแหน่ง : </b></span>' + evt.graphic.attributes.LocationId + '</div>' +
              //   '<div><span><b>สถานะ : </b></span>' + data.ApprovalStatusName + '</div>'
              //   ;

              var content = '<div style="font-size:15px;"><span><b>ชื่อโครงการ : </b></span>' + evt.graphic.attributes.NameSubject + '</div>' +
                '<div style="margin-top:10px;"><span><b>พื้นที่ : </b></span>' + data.PublicUtilityRequestCounties + '</div>' +
                '<div><span><b>สถานะโครงการ : </b></span>' + data.ApprovalStatusName + '</div>' +
                '<div><span><b>เจ้าของโครงการ : </b></span>' + evt.graphic.attributes.NameOfCoordinator + '</div>' +
                '<div><span><b>เบอร์ติดต่อ : </b></span>' + 'N/A' + '</div>'
                ;

              map.infoWindow.setTitle("");
              map.infoWindow.setContent(content);
              map.infoWindow.show(evt.screenPoint, map.getInfoWindowAnchor(evt.screenPoint));
              map.infoWindow.show(evt.screenPoint, map.getInfoWindowAnchor(evt.screenPoint));
              console.log(evt.screenPoint,'evt.screenPoint');

            },
            (error) => {
              console.error('An error occurred:', error);
            }
          );



        }
      });
    });
  }

}
