import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { ApiService } from '../../../services/api.service';
import { Tree } from '@angular/router/src/utils/tree';
import { SuccessdialogComponent } from '../../../handledialog/successdialog/successdialog.component';
import { CompletedialogComponent } from '../../../handledialog/completedialog/completedialog.component';
import { ComfirmdialogComponent } from '../../../handledialog/comfirmdialog/comfirmdialog.component';
import { ModelInfo } from './modelinfocomplaint.model';
import { FuseConfirmDialogComponent } from '../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import { fuseAnimations } from '../../../../@fuse/animations';
import { FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../../../services/authentication.service';
import { SenddepartmentComponent } from '../../../handledialog/senddepartment/senddepartment.component';
export interface Title {
  value: number;
  titlename: string;
}
export interface Food {
  value: number;
  viewValue: string;
}
@Component({
  selector: 'app-modalinfocomplaint',
  templateUrl: './modalinfocomplaint.component.html',
  styleUrls: ['./modalinfocomplaint.component.scss'],
  animations: fuseAnimations
})
/** modalinfocomplaint component*/
export class ModalinfocomplaintComponent implements OnInit {
  public Depart;
  public Model: ModelInfo;
  public Project;
  public send;
  shows1 = false;
  shows2 = false;
  shows3 = false;
  shows4 = false;
  showsprogress1 = false;
  showsprogress2 = false;
  showsprogress3 = false;
  showsprogress4 = false;
  showedit = false;
  productForm: FormGroup;
  projectGalleryForm: FormArray;
  projectGalleryAnswerForm: FormArray;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  currentUserFullname: string;
  title: Title[] = [
    { value: 1, titlename: 'นาย' },
    { value: 2, titlename: 'นาง' },
    { value: 3, titlename: 'นางสาว' },
  ];
  foods: Food[] = [
    { value: 1, viewValue: 'รอการชี้แจง' },
    { value: 2, viewValue: 'กำลังดำเนินการแก้ไข' },
    { value: 3, viewValue: 'แจ้งผล' },
    { value: 4, viewValue: 'ปิดเรื่อง' }
  ];
  /** modalinfocomplaint ctor */
  constructor(public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ModalinfocomplaintComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private _formBuilder: FormBuilder,
    public _matDialog: MatDialog,
    private authenticationService: AuthenticationService) {
    this.currentUserFullname = this.authenticationService.currentUserValue ? this.authenticationService.currentUserValue.fullname : "";
  }
  ngOnInit(): void {
    this.getComplaintInfo();
    this.getDepart();
    this.getOriginalPlan();
    this.productForm = this.productForm = this._formBuilder.group({
      complaintId: [''],
      nameComplaint: ['', [Validators.required]],
      originalPlanId: [''],
      nameProject: [''],
      complaintNumber: [''],
      departmentId: [''],
      c_DepartmentName: [''],
      projectManagerName: [''],
      gpS_Lat: [''],
      gpS_Long: [''],
      distanceLength: [''],
      address: [''],
      telephone: [''],
      email: [''],
      problem: [''],
      problemAddress: [''],
      complaintSubtype: [''],
      complainttype: [''],
      designer: [''],
      supervisor: [''],
      sendDepartmentDate: [''],
      complaintDate: [''],
      complaintStatusId: [''],
      name: {
        titleTH: '',
        firstNameTH: '',
        lastNameTH: '',
      },
      titleTH: [''],
      firstNameTH: [''],
      lastNameTH: [''],
      fileGalleries: [null],
      fileGallerieAnswers: [null],
      complaintGalleries: [''],
      complaintAnswerGalleries: [''],
      answer: ['', [Validators.required]],
      answerDate: [''],
      approveDate: [''],
      comfirmDate: [''],
      infomantName: [''],
    });
  }
  public getComplaintInfo() {
    this.apiService.getComplaintInfo(this.data.Id).subscribe((data: ModelInfo) => {
      this.Model = data;
      this.productForm = this.createProductForm();
      if (this.Model.complaintStatusId == 1) {
        this.shows1 = true;
        this.shows2 = false;
        this.shows3 = false;
        this.showsprogress1 = true;
        this.showsprogress2 = false;
        this.showsprogress3 = false;
        this.showsprogress4 = false;
        this.showedit = true;
      }
      else if (this.Model.complaintStatusId == 2) {
        this.shows2 = true;
        this.shows1 = false;
        this.shows3 = false;
        this.showsprogress1 = false;
        this.showsprogress2 = true;
        this.showsprogress3 = false;
        this.showsprogress4 = false;
        this.showedit = true;
      }
      else if (this.Model.complaintStatusId == 3) {
        this.shows3 = true;
        this.shows2 = false;
        this.shows1 = false;
        this.showsprogress1 = false;
        this.showsprogress2 = false;
        this.showsprogress3 = true;
        this.showsprogress4 = false;
        this.showedit = true;
      }
      else if (this.Model.complaintStatusId == 4) {
        this.shows4 = true;
        this.shows1 = false;
        this.shows2 = false;
        this.shows3 = false;
        this.showsprogress1 = false;
        this.showsprogress2 = false;
        this.showsprogress3 = false;
        this.showsprogress4 = true;
        this.showedit = false;
      }
      //this.cancleedit();
    });
  }
  changeedit() {
    this.showedit = true;
    this.shows1 = false;
    this.shows2 = false;
    this.shows3 = false;
  }
  cancleedit() {
    this.showedit = false;
    this.getComplaintInfo();
    //if (this.Model != null) {
    //  if (this.Model.complaintStatusId == 1) {
    //    this.shows1 = true;
    //  }
    //  else if (this.Model.complaintStatusId == 2) {
    //    this.shows2 = true;
    //  }
    //  else if (this.Model.complaintStatusId == 3) {
    //    this.shows3 = true;
    //  }
    //}
  }

  sendDepart() {
    const dialogRef = this.dialog.open(SenddepartmentComponent, {
      panelClass: 'myapp-no-padding-dialog',
      // panelClass: 'bookCardDialog',
      // maxWidth: '100vw',
      // maxHeight: '100vh',
      // height: '80%',
      // width: '80%',
      data: {
        Id: this.Model.complaintId,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getComplaintInfo();
    });
  }

  SaveChgangeedit(id, except) {
    //this.showedit = false;
    console.log(this.productForm.getRawValue());
    this.send = this.productForm.getRawValue();
    if (id > 0 && id != 5) {
      this.send.complaintStatusId = id;
    }
    //this.Depart.forEach((value) => {
    //  if (value.DepartmentId == this.send.departmentId) {
    //    this.send.c_DepartmentName = value.Name;
    //    return;
    //  } 
    //});
    if (id == 0) {
      this.send.sendDepartmentDate = new Date().toISOString();
    }
    else if (id == 2) {
      this.send.approveDate = new Date().toISOString();
      this.send.infomantName = this.currentUserFullname;
    }
    else if (id == 3) {
      this.send.answerDate = new Date().toISOString();
      this.send.infomantName = this.currentUserFullname;
    }
    else if (id == 4) {
      this.send.comfirmDate = new Date().toISOString();
    }

    if ((id == 2 || id == 3) && !(except)) {
      const dialogRef = this.dialog.open(ComfirmdialogComponent, {});

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.apiService.SaveChgangeEdit(this.send).subscribe((response) => {
            //console.log(response);
            if (response != null) {
              this.getComplaintInfo();
              response = null;
              const success = this.dialog.open(CompletedialogComponent, {
                panelClass: 'myapp-no-padding-dialog',
                data: {
                  message: response
                }
              });
            }
          });
        }
      });
    }
    else {
      this.apiService.SaveChgangeEdit(this.send).subscribe((response) => {
        //console.log(response);
        if (response != null) {
          this.getComplaintInfo();
          response = null;
          const success = this.dialog.open(CompletedialogComponent, {
            panelClass: 'myapp-no-padding-dialog',
            data: {
              message: response
            }
          });
        }
      });
    }

  };

  get complaintAnswerGalleries() {
    return <FormArray>this.productForm.get('complaintAnswerGalleries');
  }

  get complaintGalleries() {
    return <FormArray>this.productForm.get('complaintGalleries');
  }

  public getDepart() {
    this.apiService.getDepartment().subscribe((data: object[]) => {
      this.Depart = data;
      this.Depart = this.Depart.Results;
    });
  }
  public getOriginalPlan() {
    this.apiService.getOriginalPlan().subscribe((data: object[]) => {
      this.Project = data;
      this.Project = this.Project.Results;
    });
  }

  createGallery(item?): FormGroup {
    item = item || {};
    return this._formBuilder.group({
      complaintGalleryId: [item.complaintGalleryId],
      picture: [item.picture],
      description: [item.description],
      contentEN: [item.contentEN]
    });
  }


  createAnswerGallery(item?): FormGroup {
    item = item || {};
    return this._formBuilder.group({
      complaintAnswerGalleryId: [item.complaintAnswerGalleryId],
      picture: [item.picture],
      description: [item.description],
      contentEN: [item.contentEN]
    });
  }

  createProductForm(): FormGroup {
    var complaintGalleries = this.Model.complaintGalleries.map(gallery => {
      return this.createGallery(gallery);
    });
    var complaintAnswerGalleries = this.Model.complaintAnswerGalleries.map(galleryanswer => {
      return this.createAnswerGallery(galleryanswer);
    });

    return this._formBuilder.group({
      complaintId: [this.Model.complaintId],
      nameComplaint: [this.Model.nameComplaint, [Validators.required]],
      complaintNumber: [this.Model.complaintNumber],
      originalPlanId: [this.Model.originalPlanId],
      nameProject: [this.Model.nameProject],
      departmentId: [this.Model.departmentId],
      c_DepartmentName: [this.Model.c_DepartmentName],
      projectManagerName: [this.Model.projectManagerName],
      gpS_Lat: [this.Model.gpS_Lat],
      gpS_Long: [this.Model.gpS_Long],
      distanceLength: [this.Model.distanceLength],
      address: [this.Model.address],
      telephone: [this.Model.telephone/*, [Validators.required]*/],
      email: [this.Model.email/*, [Validators.required]*/],
      problem: [this.Model.problem],
      problemAddress: [this.Model.problemAddress],
      complaintSubtype: [this.Model.complaintSubtype],
      complainttype: [this.Model.complainttype],
      designer: [this.Model.designer],
      supervisor: [this.Model.supervisor],
      sendDepartmentDate: [this.Model.sendDepartmentDate],
      complaintDate: [this.Model.complaintDate],
      complaintStatusId: [this.Model.complaintStatusId],
      name: {
        titleTH: this.Model.name.titleTH,
        firstNameTH: this.Model.name.firstNameTH,
        lastNameTH: this.Model.name.lastNameTH,
      },
      titleTH: [this.Model.name.titleTH/*, [Validators.required]*/],
      firstNameTH: [this.Model.name.firstNameTH/*, [Validators.required]*/],
      lastNameTH: [this.Model.name.lastNameTH/*, [Validators.required]*/],
      complaintGalleries: this._formBuilder.array(complaintGalleries),
      fileGalleries: [null],
      fileGallerieAnswers: [null],
      complaintAnswerGalleries: this._formBuilder.array(complaintAnswerGalleries),
      answer: [this.Model.answer, [Validators.required]],
      answerDate: [this.Model.answerDate],
      approveDate: [this.Model.approveDate],
      comfirmDate: [this.Model.comfirmDate],
      infomantName: [this.Model.infomantName],
    });

  }

  onFileGalleryChanged(files: any) {
    if (files.length === 0) {
      return;
    }

    this.projectGalleryForm = this.productForm.get('complaintGalleries') as FormArray;

    this.apiService.uploadAvatar(files)
      .then((response: any) => {
        // Set image avatar
        response.dbPaths.forEach(path => {
          let url = path;
          this.projectGalleryForm.push(this.createGallery({
            complaintGalleryId: 0,
            picture: url,
            description: ''
          }));
        })

      });
  }


  onFileGalleryAnswerChanged(files: any) {
    if (files.length === 0) {
      return;
    }

    this.projectGalleryAnswerForm = this.productForm.get('complaintAnswerGalleries') as FormArray;

    this.apiService.uploadAvatar(files)
      .then((response: any) => {
        // Set image avatar
        response.dbPaths.forEach(path => {
          let url = path;
          this.projectGalleryAnswerForm.push(this.createAnswerGallery({
            complaintAnswerGalleryId: 0,
            picture: url,
            description: ''
          }));
        })

      });
  }

  removeAnswerGallery(index): void {
    this.projectGalleryAnswerForm = this.productForm.get('complaintAnswerGalleries') as FormArray;
    this.projectGalleryAnswerForm.removeAt(index);
    this.projectGalleryAnswerForm.markAsDirty();

  }
}
